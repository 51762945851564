import { combineReducers } from 'redux';

import cartDataReducer from '../../../modules/order-fulfillment/data/reducers/cartDataReducer';
import userAuthData from '../../../modules/order-fulfillment/data/reducers/authReducer';
import businessData from '../../../modules/order-fulfillment/data/reducers/businessReducer';
import stockOrderListReducer from '../../../modules/order-fulfillment/data/reducers/stockOrderReducer';
import multipleWishlistDataReducer from '../../../modules/order-fulfillment/data/reducers/multipleWishlistDataReducer';




export const orderFulfillmentReducers = combineReducers({
    cartData: cartDataReducer,
    authData:userAuthData,
    businessData:businessData,
    multipleWishlistData:multipleWishlistDataReducer,
    soData: stockOrderListReducer
});
