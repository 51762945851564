import {ADD_CART,REMOVE_CART,CHECKOUT_AND_EMPTY_CART,UPDATE_ORDER_DETAILS,RESET_CART} from './actionTypes';

export function addToCart(data){
    console.log("data",data)
    return {
        type: ADD_CART,
        data
    };
}

export function removeFromCart(data){
    return{
        type:REMOVE_CART,
        data
    };
}

export function checkoutEmptyCart(){
    return{
        type:CHECKOUT_AND_EMPTY_CART
    };
}

export function UpdateOrderDetails(){
    return{
        type:UPDATE_ORDER_DETAILS
    };
}

export function resetCart(){
    return{
        type:RESET_CART
    };
}