import React, { useEffect, useState, } from 'react';
import { Theme, createStyles, makeStyles, fade, } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Login from '../../screens/Authentication/Login'
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { OrderFulfilllmentPaths } from '../../Routes/Routes'
import isEqual from 'lodash/isEqual';
import { InputAdornment } from '@material-ui/core';
import { LOGIN_HEADERS, USER_AUTH_TOKEN } from '../../api/auth';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { setSelectedLocations } from '../../../../store/actions/userLocation';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import MenuItem from '@material-ui/core/MenuItem';
import { Input } from '@material-ui/core/';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'hidden'
    },
    formControl: {
      margin: theme.spacing(1),
      color: '#FFFFFF',
      position: 'relative',
      width: 'auto'
    },
    select: {
      color: GlobalStyles.lightTheme,
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightTheme,
      },
      '&:after': {
        borderColor: GlobalStyles.lightTheme,

      },
      maxWidth: '15rem',
      minWidth: '9rem',
      overflow: 'hidden'
    },
    whiteText: {
      textDecorationColor: '#FFFFFF'
    },
    nav: {
      display: 'flex',
      flexDirectiom: 'row',
      placeContent: 'center space-between',
      alignItems: 'center',
      width: '100%'
    },
    logoBreadCrumb: {
      flexDirection: 'row',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center',
    },
    breadCrumbs: {
      placeContent: 'center flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',

    },

    toolbar: theme.mixins.toolbar,
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        // width: 'auto',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    box: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '30%',
      alignSelf: 'flex-end'
    },
    logoButton: {
      width: 200,
      marginRight: theme.spacing(1),
      fontSize: 16,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    input: {
      border: '2px, dotted',
      backgroundColor: '#FFFFFF',
      borderRadius: '0.3rem',
      padding: '0rem 0.4rem',
    },
    iconButton: {
      padding: 10,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },

  }),
);


const NavBar = (props: any) => {
  const classes = useStyles({});
  // const history = useHistory();
  const [loginDialogBox, setLoginDialogBox] = useState(false)
  //   const navigate = (path) => { history.push(path); };
  const [selectedBusinessLocationLocal, setSelectedBussinessLocationLocal] = useState(null)
  const [singleLocation, setSingleLocation] = useState(false)
  const { placeholder, authData, crumbsData, selectedCrumb, logoClick } = props;

  const business = props.businessData.id

  useEffect(() => {

    console.log("IS MOBILE SCREEN", props.isMobileScreen)
    console.log("PROPS===========>",props)
    if (props.businessData.organizational_unit.length === 1) {
      setSingleLocation(true)
    }

  }, [])

  useEffect(() => {
    if (props.history.location.pathname != OrderFulfilllmentPaths.wishList) {
      if (props.history.hasOwnProperty('state')) {
        if (props.history.state.from === OrderFulfilllmentPaths.wishList) {
          if (props.history.state.hasOwnProperty('editedlist')) {
            if (props.history.state.editedlist != null) {
              let editedLists = props.history.state.editedlist
              let savedLists = props.authData.userDetails.saved_list
              !isEqual(editedLists, savedLists) ? props.history.push(OrderFulfilllmentPaths.wishList) : null
            }
          }
        }
      }
    }
  }, [props.history.state])



  // const _signOutAsync = async () => {
  //   try {
  //     await localStorage.removeItem('headers');
  //     props.signOutUser();
  //   } catch {
  //     // console.log('error in localStorage');
  //   }
  //   setTimeout(() => (window.location.href = '/'), 200);
  // };

  const navigateToCartScreen = () => {

    const cartUrl = OrderFulfilllmentPaths.cart  //TODO CHANGE THIS LATER
    props.history.state = { ...props.history.state, from: props.location.pathname }
    props.history.push(cartUrl);
  }

  const handleCartClick = () => {
    navigateToCartScreen()
  }

  const handleBusinessNameClick = () => {
    //  console.log("props in  navbar",props)
    if (props.showBreadCrumbs) {
      props.setFilterByCommodityGroup(null)
      // props.setBreadCrums([]) to-do change
      props.setShowBreadCrumbs(false)
    }
    const homeUrl = OrderFulfilllmentPaths.home //TODO CHANGE THIS LATER
    props.location.pathname === homeUrl ? props.setSearchInput('') : null
    props.history.state = { ...props.history.state, from: props.location.pathname }
    props.history.push(homeUrl);
  }

  const handleSearchInputChange = (event) => {
    //  console.log("event.target.value", event.target.value)
    //  console.log("event.target.length",event.target.value.length)
    props.setSearchInput(event.target.value)


  }
  const handleSearchInputButtonClick = (event) => {
    props.setSearchButtonClicked(true)
  }

  const handleClrClick = (event) => {
    props.setSearchInput('')
  }

  const getImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImagePath()
    }
  }

  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default1.png';
    return window.location.origin + imagePath;
  };


  const handleLocationChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let locationuuid = event.target.value
    props.businessData.organizational_unit.map((obj) => (
      obj.uuid === locationuuid ? (obj['isSelected'] = true,setSelectedBussinessLocationLocal(obj)) : obj['isSelected'] = false
    )
    )
    console.log("PROPS AFTER CHANGE===>", props.businessData.organizational_unit)
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {/* <Container > */}
        <Toolbar style={{ minHeight: 70, paddingLeft: '10px' }}>
          <div className={classes.nav}>

            <div className={classes.logoBreadCrumb}>
              <div className={classes.logoBreadCrumb}>
                <Button size="small" onClick={handleBusinessNameClick} >
                  {/* <img src={'/assets/bgLogo.png'} width="50" height="50"></img> */}

                  {/* {!props.businessData ? null : props.businessData.image_data ? */}
                    <img
                    alt={props.businessData ? props.businessData.name : ''}
                    src={getImagePath(props.businessData)}
                    style={{ objectFit: 'contain', width: "50px", height: "50px" }}
                  />
                    {/* :
                    <div>
                      <Typography>
                      {props.businessData.name.split(" ")[0]}
                      </Typography>
                      <Typography>
                      {props.businessData.name.split(" ")[1]}
                      </Typography>
                    </div>
                  } */}

                </Button>
                <IconButton style={{ color: "#FFFFFF", marginLeft: '5px' }} className={classes.iconButton} aria-label="menu" onClick={() => props.handleDrawer()}
                  edge="start">
                  <MenuIcon />
                </IconButton>
                {props.location && props.location.pathname.includes(OrderFulfilllmentPaths.home) &&
                  <InputBase
                    className={classes.input}
                    placeholder="Search"
                    value={props.searchInput}
                    onChange={handleSearchInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        {props.searchInput && props.searchInput.length > 0 &&
                          <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleClrClick}>
                            <ClearIcon />
                          </IconButton>
                        }
                        <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSearchInputButtonClick}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                }
                {/* <div className={classes.breadCrumbs}>
                  {
                    crumbsData.length > 0 &&
                    <Breadcrumb
                      crumbsData={crumbsData}
                      selectedCrumb={selectedCrumb}
                    />
                  }
                </div> */}
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
              {!singleLocation &&
                <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end', paddingBottom: '7px', marginRight: '5px', marginLeft: '5px' }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      id="select-label"
                      style={{ color: GlobalStyles.lightTheme }}
                    >
                      Location
                    </InputLabel>
                    <Select
                      value={selectedBusinessLocationLocal!=null ? selectedBusinessLocationLocal.uuid : "" }
                      labelId="select-label"
                      id="select-location"
                      onChange={handleLocationChange} 
                      className={classes.select}
                      style={props.isMobileScreen ? {minWidth:'5rem', maxWidth:'5rem', marginRight:0}:null}
                      inputProps={{
                        name: 'location',
                        id: 'location_id',
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >

                      {

                        props.businessData.organizational_unit.map((b: any) => (
                          <MenuItem value={b.uuid} key={String(b.uuid)}>
                            {
                              b.name
                            }
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              }

              <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
                {/* <div className={classes.box}>
                    <SearchBar
                        onSearch={onSearch}
                        onBlur={onSearchInputBlur}
                        onFocus={onSearchInputFocus}
                        searchTerm={searchTerm}
                        clearSearch={clearSearch}
                        placeholder={'SearchProducts...'} 
                    />
                  </div> */}


                {/* <Button size="small" aria-label="small outlined button group" color="secondary" onClick={handleCartClick} >
                          
                  </Button> */}
                <IconButton style={props.isMobileScreen ? {paddingLeft:0, paddingRight:0}:null} onClick={handleCartClick}>
                  <ShoppingCartOutlinedIcon style={{ color: '#FFFFFF', marginLeft: '5px'}} />
                  <Typography variant="h6" style={{ color: '#FFFFFF',paddingLeft: '5px' }} >{props.userData.cartData.total_items != 0 ? props.userData.cartData.total_items : ''}</Typography>
                </IconButton>
              </div>
            </div>

          </div>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      {/* {/* { */}
      {/* <Login
        isOpen={loginDialogBox}
        onClose={null}
        setLoginDialogBox={setLoginDialogBox}
      /> */}

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData,
  };
};

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     signOutUser: () => dispatch(logOutReferenceCatalogUser()),
//   };
// };
export default connect(mapStateToProps, null)(NavBar);


