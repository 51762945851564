import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Typography, Paper, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, Button } from '@material-ui/core/';
import { UserLogin } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { saveUserLoginData } from '../../data/actions/auth';
import { addNewWishlist, removeNewWishlist } from '../../data/actions/multipleWishListAction'
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import { OrderFulfilllmentPaths } from '../../Routes/Routes'
import { LOGIN_HEADERS } from '../../api/auth';
import { API_URLS } from '../../api/Api';

import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // marginBottom: 8,
      width: '100%'
    },
    dialogContent: {
      overflow: 'hidden',
      // display: 'flex', 
      // flexDirection: 'column', 
      // width: '100%' ,
      // justifyContent:'space-between'
    },

    container: {
      // flex: 1,
      minHeight: '100vh',
      alignItems: 'center',
      // paddingHorizontal: 24,
      paddingBottom: 0,
      paddingTop: 64,
      backgroundColor: 'white',
      overflow: 'hidden !important'
    },
    progressBar: {
      padding: 1
    },
    header: {
      paddingVertical: 12,
      paddingTop: 24
    },
    card: {
      width: '60%',
      maxWidth: 511.3,
      minWidth: 340,
      margin: 'auto',
      // minHeight: '70vh',
      borderRadius: 5,
    },
    form: {
      marginBottom: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnWrapper: {
      padding: 16,
      paddingTop: 32,
      paddingBottom: 32,
    },
    input: {
      width: '55%',
      fontSize: 16
    },
    label: {
      fontSize: 18
    },
    passswordInput: {
      fontSize: 32,
      textAlign: 'center',
      padding: 6,
    },
    signInBtn: {
      borderRadius: 4,
      width: 120,
      maxWidth: 120,
      alignSelf: 'center',
    },
    btnText: {
      color: 'white',
      fontSize: 18,
    },
    newUser: {
      backgroundColor: GlobalStyles.primaryColor,
      padding: 6,
      borderRadius: 4,
      "&:hover": {
        cursor: 'pointer',
      }
    },
    help: {
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }


  }),
);




const Login = (props: any) => {

  const classes = useStyles({});
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false)
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const business = props.businessData.id
  const [isLoading, setLoading] = useState(false)

  const handleLoginClick = async (event: any) => {
    event.preventDefault();
    setLoading(true)
    const data = { 'username': username, 'password': password, 'business': business }
    //console.log("Log-In", data)

    UserLogin(data)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setLoading(false)
        const data_to_store = {
          isAuthenticated: true,
          userDetails: data
        }
        props.saveUserLoginData(data_to_store)
        if (data.saved_list) {
          props.addNewWishlist(data.saved_list)
        }
        const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
        console.log("USER DATA ===>",authHeaders)
        localStorage.setItem('public_token_verified', JSON.stringify(true));
        localStorage.setItem('headers', JSON.stringify(authHeaders));
        props.showToast({
          message: 'User Logged In successfully!',
          options: ToastSuccess,
        });
        // props.history.push(OrderFulfilllmentPaths.home);
        console.log("LOCATION.STATE",props.location)
        props.history.push(props.location.state && props.location.state.prevPath ? props.history.location.state.prevPath : OrderFulfilllmentPaths.home);
      })
      .catch((errorData: any) => {
        setLoading(false)
        //console.log("Error", errorData)

        const defaultMsg = "Oops!! An error ocurred.";
        let toastMsg;
        if (errorData) {
          if (errorData.data && errorData.data.error === 'Invalid Credentials') {
            toastMsg = 'Error! Invalid Credentials';
          } else if (errorData.status && errorData.status === 500) {
            toastMsg = "Server Error! please try again later.";
          } else if (errorData.response && errorData.response.status === 0) {
            toastMsg = "Could not sign-in! Please check you internet connection.";
          } else {
            toastMsg = defaultMsg;
          }
        } else {
          toastMsg = defaultMsg;
        }
        props.showToast({ message: toastMsg, options: ToastError, });

        // if (error.status && error.status === 401 || error.status === 404 || error.status === 403) {
        //   props.showToast({
        //     message: error.data.error,
        //     options: ToastError,
        //   });
        // }
        // else {
        //   props.showToast({
        //     message: 'Server Error Unable to Login',
        //     options: ToastError,
        //   });
        // }
        // console.log("inside error", error)
        // // setLoading(false);
        // if (error) {
        //   if (error.status && error.status === 500) {
        //     setEmptyDataSourceMessage(ERROR_MSG);
        //     setErrorStatusVisible(false);
        //   }
        // }

      });
  }




  const handleCancelButton = () => {
    props.setLoginDialogBox(false)

  }

  const handleUserNameChange = (event) => {
    setUserName(event.target.value)
  }
  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }


  const renderLogin = () => {
    return (
      <div className={classes.container}>
        <Paper elevation={3} className={classes.card}>
          {
            isLoading &&
            <LinearProgress color="secondary" className={classes.progressBar} />
          }
          <div className={classes.header}>
            <Typography style={{
              fontSize: 32,
              textAlign: 'center',
              color: GlobalStyles.primaryColor
            }}>Sign In </Typography>
          </div>
          <form style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
            onSubmit={handleLoginClick}
          >
            <TextField
              style={{ width: '55%' }}
              id="username"
              label="Username"
              autoComplete="username"
              value={username}
              onChange={handleUserNameChange}
              margin="normal" />
            <TextField
              className={classes.input}
              id="standard-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              margin="normal" />
            <div className={classes.btnWrapper}>
              <Button variant="contained"
                color="primary"
                className={classes.signInBtn}
                disabled={loginButtonDisabled}
                type="submit">
                Login
              </Button>
            </div>
          </form>
          {/* <div style={{display:'flex', justifyContent:'space-between'}}>
            <div style={{ alignContent:'flex-start', margin: 20}} className={classes.newUser}>
            <Typography variant="subtitle1" style={{color: 'white',}}>
              {`New User?`}
            </Typography>
            </div>
            <div style={{ alignContent:'flex-end', margin: 20}} >
            <Typography variant="subtitle1" color={`primary`} className={classes.help} >
              {`Help`}
            </Typography>
            </div>
          </div> */}
        </Paper>
      </div>
    )
  }



  return (
    <div  >
      {renderLogin()}
    </div>
  );//check whether business allows user to self sign up, show the sign up button and redirect to sign up screen.

}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveUserLoginData: (p) => { dispatch(saveUserLoginData(p)) },
    removeNewWishlist: () => { dispatch(removeNewWishlist()) },
    addNewWishlist: (p) => { dispatch(addNewWishlist(p)) },
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);