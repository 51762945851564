
import React, { useEffect, useLayoutEffect,useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, List, Divider,ListItem, ListItemText, TextField, Dialog, DialogActions, 
  DialogContent, DialogTitle, Typography, Grid, Card, CardContent, Paper, Menu,
  MenuItem, SvgIcon ,CircularProgress,Tooltip, Select
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import { addNewWishlist, removeNewWishlist } from '../../data/actions/multipleWishListAction';
import { addToCart } from '../../data/actions/cartActions';
import { OrderFulfilllmentPaths } from '../../Routes/Routes';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import cloneDeep from 'lodash/cloneDeep';
import { getProductPriceForPos } from '../../../products/lib/price'

import { getSavedListsByCustomer, addOrUpdateSavedList } from '../../api/Api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { setSelectedLocations } from '../../../../store/actions/userLocation';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
const useStyles = makeStyles({
  main: {
    display: 'flex',
    width: '100%', //FOR MOBILE VIEW  width will be fit-content 
    // paddingTop: 5,
    paddingLeft: 4,
    paddingBottom: 20,
    paddingRight: 4,
    // margin: 10,
    marginLeft:'-4px',
    // paddingBottom: 26,
    flexDirection: 'column',
    height: '100%',

  },

  main2: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',

  },

  heading: {
    display: 'flex',
    width: '100%',
    paddingRight: 10,
    marginTop: 10,
  },

  paperhead: {
    justifyContent: 'space-between',
  },

  paper: {
    marginTop: 20,
    width: '20%',
    marginRight: 5,
    // minWidth: 250,
    height: 'inherit',
    //marginBottom: '-20px'
  },

  itemsMain: {
    //margin: 7,
    marginTop: 20,
    marginLeft: 5,
    height: '100%',
    // height: 'inherit',
  },

  list: {
    width: 250,
  },

  fullList: {
    width: 'auto',
  },

  menuIcon: {
    marginLeft: 655,
    marginTop: 10,
  },

  cardGrid: {
    margin: '4px',
    flexGrow: 1,
    padding: '4px',
    height: '250px',
    width: '200px',
  },

  tabImage: {
    height: '150px',
    width: '200px',
    marginLeft: '10px',
    alignSelf: 'center',
    "&:hover": {
      cursor: 'pointer',
    }
  },

  cardContentGrid: {
    flexGrow: 1,
    padding: '18px',
    height: '156px',
    width: '176px',
  },

  card: {
    flexGrow: 1,
    paddingBottom: '1px',
    // height: '50%',
    // width: '100%',
    display: 'flex',
    "&:hover": {
      cursor: 'pointer',
    }
  },

  cardContent: {
    // paddingBottom:'2px',
    // flexGrow: 1,
    // height: '15%',
    width: '100%',
    display: 'flex',
    // flexDirection:'row'
  },

  cardAction: {
    display: 'flex',
    // justifyContent:'flex-start',
    // marginTop:'55px',
    // marginLeft : '-5px',
    float: 'right',
    padding: '8px'
  },

  grid: {
    flexGrow: 1,
    padding: '10px'
  },

  overflow: {
    overflowWrap: 'break-word',
  },

  wishDropdown: {
    position: 'absolute',
    left: '1500',
    top: '180',
    marginLeft: '153'
  },
  deleteBtn: {
    backgroundColor: GlobalStyles.red,
    color: "#fffde7",
    textDecorationColor: 'white',
    "&:hover": {
      backgroundColor: GlobalStyles.red,
    }
  },
  pageTitle: {
      padding: '0 4px',
      fontSize: '1.5rem'
    },

});

const WishList = (props: any) => {
  const classes = useStyles();
  const [wishListId, setWishlistId] = useState(0);
  const [wishlistname, setWishlistname] = useState(null);
  const [isDisable, setIsDisable] = React.useState(true)
  const [openDelPopup, setOpenDelPopup] = React.useState(false);
  const [openClrPopup, setOpenClrPopup] = React.useState(false);
  const [isOpenWishlist, setOpenWishlist] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEdited,setIsEdited]=useState(false)
  const [originalSavedLists, setOriginalSavedLists]=useState(null as any);
  const [editedLists, setEditedLists]=useState(null as any);
  const [selectedSavedList, setSelectedSavedList] = useState(null as any)
  const [selectedProduct, setSelectedProduct] = useState( null as  any)
  const [ isOpenSaveChangesDialog, setOpenSaveChangesDialog ] = useState(false)
  const [ isOpenSaveChangesOnExitDialog, setOpenSaveChangesOnExitDialog ] = useState(false)
  const [isLoading, setLoading] =useState(false)

  useEffect(() => {
    if (props.history.hasOwnProperty('state')) {  
        if (props.history.state.hasOwnProperty('editedlist')) {
          if(props.history.state.editedlist!= null){
            setOpenSaveChangesOnExitDialog(true)
        }
      }    
  }
  }, [])

  useEffect(() => {
    console.log("=============>isedited",editedLists)
    //alert("at the end")
    console.log("location state",props.location.state)
    if(props.location.state != OrderFulfilllmentPaths.wishList){
    
   handleIsSave()
      
  }
  }, [props.location])

  useEffect(() => {
    console.log("wishlist props",props)
  }, [props])

  const handleIsSave=()=>{
    console.log("UPDATED LIST ==>",editedLists)
    let result =isEqual(originalSavedLists,editedLists)
    console.log(props)
    if(result==false)
    alert("unsaved changes")
    //() => handleSaveChanges()  
    

  }
  useEffect(() => {
    if (props.location) {
      if (props.location.pathname === OrderFulfilllmentPaths.wishList) {
        if (props.userData) {
          if (props.userData.authData.isAunthenticated === false) {
            props.history.push(OrderFulfilllmentPaths.signIn)
          }
          else {
            if (!editedLists) {
              let lists = props.userData.multipleWishlistData.lists && props.userData.multipleWishlistData.lists.length > 0 ? cloneDeep(props.userData.multipleWishlistData.lists) : null
              console.log("Listsss", lists)
              let updatedList = []
              if (!lists) {
                if (props.userData.authData.isAuthenticated) {
                  let savedList = cloneDeep(props.userData.authData && props.userData.authData.userDetails.saved_list)
                  let found = savedList.find(i => i.is_default)
                  savedList = getUpdatedLists(savedList)
                  setOriginalSavedLists(cloneDeep(savedList ? savedList : null))
                  setEditedLists(cloneDeep(savedList ? savedList : null))
                  setSelectedSavedList(found && found)
                }
                else {
                  props.history.push(OrderFulfilllmentPaths.signIn)
                }
              }
              else {
                let found = lists.find(i => i.is_default)
                lists = getUpdatedLists(lists)
                setOriginalSavedLists(cloneDeep(lists))
                setEditedLists(cloneDeep(lists))
                setSelectedSavedList(found && found)
              }
            }
          }
        }
      }
    }
 
  }, [props])


  

 


  const getUpdatedLists = (list: any) => {
    
    if (list) {
      list.map(obj => obj.items && obj.items.map(i => i.product.quantity = 0))
      list.map(obj => obj.items && obj.items.map(i => i.product.quantity_total = 0))
      list.map(obj => obj.items && obj.items.map(i => i.product.price = Number(getProductPriceForPos(i.product.business_product))))
      list.map(obj => obj.items && obj.items.map(i => i.product.removed=i.product.removed ? i.product.removed : false ))
      list.map(obj => obj.removed==true ? '' : obj.removed=false)
      console.log("object removed ====>",list)
      return list
    }
  }


  useEffect(()=>{
    console.log("Original-Saved-lists", originalSavedLists)
    console.log("Edited-lists", editedLists)
    let result =isEqual(originalSavedLists,editedLists)
    if(result==false){
      props.history.state={ ...props.history.state,editedlist:cloneDeep(editedLists)}
      //props.location.state.editedlist ? props.location.state.editedlist = cloneDeep(editedLists) : { ...props.location.state,editedlist:editedLists}
      console.log("HERE props location==================>",props.history.state)
      setIsEdited(true)
      }
  },[originalSavedLists, editedLists])

  useEffect(() => {
    if(selectedSavedList){
      selectedSavedList.items  ? selectedSavedList.items.length === 0 ?  setIsDisable(true) : setIsDisable(false)  : !selectedSavedList.items ? setIsDisable(true) : null
      
    }
  }, [selectedSavedList]);


  const handleClick = (event: any,product: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product)
  };

  // const handleClickDelPopupOpen = () => {
  //   setOpenDelPopup(true);
  // };

  const handleDelPopupClose = () => {
    setOpenDelPopup(false);
  };


  const handleClickClrPopupOpen = () => {
    // if (selectedSavedList.items.length == 0) {
    //   props.showToast({
    //     message: 'Wishlist Already Empty!',
    //     options: ToastError,
    //   });
    // } else {
      setOpenDelPopup(true)
    //}
  };

  const handleClrPopupClose = () => {
    setOpenClrPopup(false);
  };



  const handleClrList = () => {
    let index = editedLists.findIndex(i=>i.id === selectedSavedList.id)
    let lists = cloneDeep(editedLists)
    lists[index].items.map(i => i.product.removed=true )
    setEditedLists(lists)
    setSelectedSavedList(lists[index])
    props.showToast({
      message: `${selectedSavedList.name} Cleared!`,
      options: ToastSuccess,
    });
    setOpenDelPopup(false)
  }





  const handleBulkAddToCart = (props: any) => {
    if (selectedSavedList && selectedSavedList.items) {
      let updatedList = cloneDeep(editedLists && editedLists)

      let alreadyAdded = []
      let newToAdd = []
      if (selectedSavedList.items && selectedSavedList.items.length === 0) {
        props.showToast({
          message: 'Wishlist is empty!',
          options: ToastError,
        });
      } else {
        selectedSavedList.items.map((p) => {
          props.userData.cartData.addedItems.map((item) => {
            if (p.product.uuid === item.uuid) {
              alreadyAdded.push(p.product)
            }
          })
        })
      }


      if (alreadyAdded.length === selectedSavedList.items.length) {
        props.showToast({
          message: 'All Products are already in the Cart!',
          options: ToastError,
        });
      } else {
        selectedSavedList && selectedSavedList.items.map((p) => {
          let item = alreadyAdded.filter(object => object.uuid === p.product.uuid)
          if (item.length == 0) {
            newToAdd.push(p.product)
          }
        })
      }

      if (newToAdd.length != 0) {
        newToAdd.map((product) => {
          props.addToCart(product)
        })
        props.showToast({
          message: 'All products added to cart!',
          options: ToastSuccess,
        });

      }

    }
  }


  const handleClickOpen = () => {
    setOpenWishlist(!isOpenWishlist);
  };
  const handleClose = () => {
    setOpenWishlist(false);
    setAnchorEl(null);
  };

  const handleToggleProduct = (listId: any, item: any) => {
    console.log("Incoming", listId, item)

    let updatedList = cloneDeep(editedLists)
    let index = updatedList.findIndex(i=>i.id === selectedSavedList.id)
    let selectedListIndex = updatedList.findIndex(i=>i.id === listId.id) //shopping-list

    updatedList[index].items = updatedList[index].items.filter(i=> i.product.uuid !== item.product.uuid)

    if(updatedList[selectedListIndex].items){
      updatedList[selectedListIndex].items.push(item)

          
    }
    else{
      let arr = [{...item}]
      updatedList[selectedListIndex]["items"] = arr
    }

    setEditedLists(updatedList)
    setSelectedSavedList(updatedList[index])

    // console.log("updatedList", updatedList)

    setAnchorEl(null);
    setOpenDelPopup(false)
  }

  const handleAddToCart = (p: any) => {
    let updated = cloneDeep(editedLists && editedLists)
    let index = updated.findIndex(i=>i.id === selectedSavedList.id)
    console.log("Selected-List", selectedSavedList)
    let existing_item = props.userData.cartData.addedItems.find(item => item.uuid === p.uuid)
    if (existing_item) {
      props.showToast({
        message: 'Product already in the cart',
        options: ToastError,
      });
    }
    else {
      console.log("Updateddd", updated, updated[index])

      props.addToCart(p)
    }
  }

  const handleWishlistRemove = (obj: any) => {
    console.log("Deleted obj", obj)
    obj['product']['removed']=true
    console.log("Object On=====>431", obj)
    let updated = cloneDeep(editedLists)
    console.log("updated On=====>433", updated)
     let index = updated.findIndex(i=>i.id === selectedSavedList.id)
     let productindex=
       console.log("index On=====>433", index)
//     updated[index].items.push(obj)
      let objindex=updated[index].items.findIndex(i=> i.product.uuid === obj.product.uuid)
      console.log("obj index On=====>439", objindex)
      //updated[index].items = updated[index].items.filter(i=> i.product.uuid == obj.uuid)
      console.log("updated On=====>437", updated)
       updated[index].items[objindex]=cloneDeep(obj)
       console.log("updated On=====>486",updated[index].items)
      setSelectedSavedList(updated[index])
  //props.addNewWishlist(updated)
 //   console.log("Updated=====>438", updated)
    setEditedLists(updated)
  }


  const handleAddNewWishlist = (wishlistname: string) => {
    setWishlistname({ wishlistname })
  };

  const handleAddNewWishlistsave = () => {
    const wishlistName = wishlistname.wishlistname;
    console.log("edited list =====>",editedLists)
    let existing_list = editedLists.find(list => list.name === wishlistName)
    if (existing_list) {
      props.showToast({
        message: 'Wishlist Already Exists!',
        options: ToastError,
      });
    }
    else {
      setLoading(true)
      let lists=editedLists
      let listDetails = { name : wishlistName}
      let customerData = props.userData.authData.userDetails
      let payload = {lists:lists, list: listDetails , customer_data : customerData}

      addOrUpdateSavedList(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: any) => {
          console.log("Add SavedList==============================>", data)
          console.log("props==============================>", props) 
          setLoading(false)
          //props.addNewWishlist(data)
          setEditedLists(null)
          setSelectedSavedList(null)
          setSelectedProduct(null)
          setOpenWishlist(false);
          //props.removeNewWishlist()

        console.log("data on 477==============================>", data) 
          let updatedList = getUpdatedLists(data)
          console.log("props==============================>", updatedList) 
          props.addNewWishlist(updatedList)

          props.showToast({
            message: 'Wishlist added Successfully!',
            options: ToastSuccess,
          });
          

        })
        .catch((error: any) => {

        });


      // const list = { name: wishlistName, id: props.mulitpleWishlistData.total_list };
      // props.addNewWishlist(list)
      
    }
  }


  const handleDeleteList = () => {
    const lists = cloneDeep(editedLists)
    console.log("test1========>",lists)
    let deletedListObj = lists.find(item => item.id === selectedSavedList.id)
    deletedListObj.removed=true
    lists.concat(deletedListObj)
    console.log("deep cloned lists ====>",lists)
    //props.removeNewWishlist(lists)
    let defaultObj = lists.find(i=>i.is_default)
    //props.addNewWishlist(lists)
    setEditedLists(lists)
    setSelectedSavedList(defaultObj)

    props.showToast({
      message: 'Wishlist Deleted!',
      options: ToastSuccess,
    });
    setOpenDelPopup(false)
  }

  // const handleClearList = (props: any, wishListId) => {

  //   props.clearList(wishListId)
  //   props.showToast({
  //     message: 'Wishlist Cleared!',
  //     options: ToastSuccess,
  //   });
  //   setOpenDelPopup(false)
  // }

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };


  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      //navigateToProductDetailScreen(data)
    }
    return null
  }
  const getAllProductsImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getImagePath()
    }
  }

  const renderListView = () => {
    let selectedList = cloneDeep(selectedSavedList && selectedSavedList)

    console.log("New-List", selectedList)

    if (selectedList && !selectedList.items) {
      return <div>
        <Grid xs={12}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

            <Typography style={{ fontSize: '0.89rem' }} >
              Wishlist is empty, let's add some items
            </Typography>
          </div>
        </Grid>
      </div>
    }
    else return selectedList && selectedList.items && selectedList.items.map((p, index) => {

      return (
        p.product.removed!=true &&
        <>
        <Grid key={index} item xs={12}>
          <Card className={classes.card} key={index}>

            <CardContent style={{ paddingBottom: '1px' }} className={classes.cardContent}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div onClick={() => handlePanelProductImageOnClick(p)} style={{ flexDirection: 'column', alignContent: 'center', width: '100%' }}>
                  <Typography style={{ fontSize: '0.89rem' }} >
                    {p.product.business_product.name}
                  </Typography>
                  {!props.userData.businessData.name.includes('Chetak San Francisco') && p.product.price &&
                    <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                      {`$${p.product.price}`}
                    </Typography>
                  }
                </div>
                <Button onClick={(e: any)=>handleClick(e,p)}> <SwapHorizIcon color='primary' style={{ paddingBottom: 15 }} /></Button>
                
                {getLists(selectedList)}
                
                <Button size="small" onClick={() => handleAddToCart(p.product)}>
                  <AddShoppingCartIcon color='secondary' style={{ paddingBottom: 15 }} />
                </Button>
                <Button size="small" style={{}} onClick={() => handleWishlistRemove(p,)}>
                  <DeleteIcon color='error' style={{ paddingBottom: 15 }} />
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        </>
      )
    })
  }

  const getLists = (selectedList: any) => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          editedLists && editedLists.map((list, index) => {
            if (list.id !== selectedList.id) {
              // console.log("Productt", selectedProduct)
              return (
                <div key={index} >
                  <MenuItem onClick={() => handleToggleProduct(list, selectedProduct)} id={index}>{list.name}</MenuItem>
                </div>
              )
            }
          })
        }
      </Menu >
    )
  }

  // const createDefaultWishList = (props: any) => {
  //   if (props.userData.mulitpleWishlistData.total_list == 0) {
  //     const list = { name: props.userData.authData.userDetails.first_name + "'s wishlist", id: 0 };
  //     props.addNewWishlist(list)
  //   }
  // }


  const delListIcon = () => {
    if (selectedSavedList) {
      console.log("selected wishlist",selectedSavedList)
      // if (selectedSavedList.is_default) {
        return <Button 
        color="secondary" 
        className={!selectedSavedList.is_default ? classes.deleteBtn : null} 
        style={{ margin: 10, marginBottom: 30 }} 
        size="small" 
        variant="contained" 
        onClick={() => handleClickClrPopupOpen()}>
          { selectedSavedList.is_default ? <ClearAllIcon style={{ padding: 7 }} /> : <SvgIcon style={{ paddingTop: 10, paddingLeft: 10 }}><path d={SVG_ICONS.delete} /></SvgIcon> }
            
        </Button>
      // } else {
      //   return <Button className={classes.deleteBtn} disabled={isDisable} style={{ margin: 10, marginBottom: 30 }} size="small" variant="contained" onClick={() => handleClickClrPopupOpen()}>
      //     <SvgIcon style={{ paddingTop: 10, paddingLeft: 10 }}><path d={SVG_ICONS.delete} /></SvgIcon>
      //   </Button>
      // }
    }
  }

  const handleSaveChanges = () => {
    setLoading(true)
    console.log("Edited-lists", editedLists)
    
    let listDetails = setOpenSaveChangesOnExitDialog ? cloneDeep(props.history.state.editedlist) :cloneDeep(editedLists && editedLists)
    let customerData = props.userData.authData.userDetails
    let payload = { lists: listDetails,list:null, customer_data: customerData }
    console.log("Payload", payload)
    addOrUpdateSavedList(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        console.log("Saved-Changes", data)
        setLoading(false)
        setEditedLists(null)
        setSelectedSavedList(null)
        setSelectedProduct(null)
        let updatedList = getUpdatedLists(data)
          props.addNewWishlist(updatedList)
        props.showToast({
          message: 'Saved Successfully!',
          options: ToastSuccess,
        });
        setIsEdited(false)
        setOpenSaveChangesDialog(false)
        setOpenSaveChangesOnExitDialog(false)
      })
      .catch((error: any) => {

      });
  }

  return (

    <div>
      <Dialog
        open={isOpenSaveChangesOnExitDialog}
        onClose={()=>setOpenSaveChangesDialog(false)}
      >
        <DialogTitle>
          {"Are you sure you want to exit without saving changes?"}
        </DialogTitle>
        <DialogActions>
          <Button size="small" onClick={() => {props.history.state.editedlist=null; setOpenSaveChangesOnExitDialog(!isOpenSaveChangesOnExitDialog)}}>Discard All</Button>
          <Button
            size="small"
            autoFocus
            style={{ backgroundColor: GlobalStyles.primaryColor, textDecorationColor: 'white', color: "#fffde7",}}
            onClick={() => handleSaveChanges()} >
            Save All
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenWishlist} onClose={handleClose} >
        <form>
          <DialogTitle>ADD NEW WISHLIST</DialogTitle>
          <DialogContent>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Wishlist Name"
              type="text"
              fullWidth
              onChange={(event) => handleAddNewWishlist(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="contained" onClick={handleClose} autoFocus >
              Cancel
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={() => handleAddNewWishlistsave()}>
              ADD
            </Button>
          </DialogActions>
        </form>
      </Dialog>


      <Dialog
        open={openDelPopup}
        onClose={handleDelPopupClose}
      >
        <DialogTitle>
        {
          selectedSavedList && !selectedSavedList.is_default ? 
          `Would you like to clear the wishlist or delete the wishlist ?` : `Would you really want to clear the list?`
        }
        </DialogTitle>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleDelPopupClose} autoFocus>Cancel</Button>
          <Button disabled={isDisable} size="small" color="secondary" variant="contained" onClick={() => handleClrList()} >
            Clear All
          </Button>
          {selectedSavedList && !selectedSavedList.is_default &&
            <Button size="small" className={classes.deleteBtn} color="secondary" variant="contained" onClick={() => handleDeleteList()}  >
            Delete List
          </Button>
          }
          
        </DialogActions>
      </Dialog>


      <Dialog
        open={isOpenSaveChangesDialog}
        onClose={()=>setOpenSaveChangesDialog(false)}
      >
        <DialogTitle>
          {"Are you sure want to save the current changes?"}
        </DialogTitle>
        <DialogActions>
          <Button size="small" onClick={() => setOpenSaveChangesDialog(!isOpenSaveChangesDialog)}>Cancel</Button>
          <Button
            size="small"
            autoFocus
            style={{ backgroundColor: GlobalStyles.primaryColor, textDecorationColor: 'white', color: "#fffde7",}}
            onClick={() => handleSaveChanges()} >
            Save
          </Button>
        </DialogActions>
      </Dialog>



      {editedLists &&
        <div style={{ height: '100vh', padding: 10 }}>
          <Paper className={classes.main} elevation={4}>
            {isLoading &&
              <div style={{ alignSelf: 'center' }}>
                {
                  isLoading &&
                  <CircularProgress style={{
                    width: '32px',
                    height: '32px'
                  }} />
                }
              </div>
            }
            {!isLoading &&
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <div style={{ justifyContent: 'flex-start', marginTop: 20, }}>
                    {/* Put Arrow Icon  */}
                    <Typography variant='h5' className={classes.pageTitle} style={{ color: GlobalStyles.primaryColor }}>
                      Wishlist
                    </Typography>
                  </div>

                  <div style={{ justifyContent: 'flex-end' }}>
                    <Button style={{ marginTop: 20, marginLeft: 30 }} size="small" variant="contained" color="primary" onClick={handleClickOpen}>
                      NEW WISHLIST
                    </Button>
                    <Button style={{ marginTop: 20, marginLeft: 30 }} disabled={!isEdited} size="small" variant="contained" color="primary" onClick={()=>setOpenSaveChangesDialog(true)}>
                      Save Changes
                    </Button>
                  </div>

                </div>

                <div className={classes.main2}>
                  <Paper className={classes.paper} elevation={4}>
                    <List>
                      {
                        editedLists && sortBy(editedLists, 'id').map((list, index) => (
                          list.removed!=true &&
                          <ListItem
                            key={index}
                            button
                            divider
                            className={classes.overflow}
                            style={{ margin: 0, paddingRight: 40, paddingLeft: 4, paddingTop: 10 }}
                            onClick={() => setSelectedSavedList(list)}
                          >
                            <ListItemText className={classes.overflow}>{list.name}</ListItemText>
                          </ListItem>
                        )
                        )
                      }</List>

                  </Paper>
                  <div style={{ height: '100%', width: "100%" }}>
                    <Paper elevation={4} className={classes.itemsMain}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ justifyContent: 'flex-start', }}>
                          <Typography variant="h5" style={{ paddingTop: 15, paddingLeft: 10, color: GlobalStyles.primaryColor }} >
                            {`${selectedSavedList && selectedSavedList.name}`}
                          </Typography>
                        </div>
                        <div style={{ justifyContent: 'flex-end' }}>
                          <Button disabled={isDisable} style={{ margin: 10, marginBottom: 30 }} size="small" variant="contained" color="primary" onClick={() => handleBulkAddToCart(props)}>
                            {<SvgIcon style={{ padding: 7 }}><path d={SVG_ICONS.addAllItems} /></SvgIcon>}
                          </Button>
                          {delListIcon()}
                        </div>
                      </div>
                      <Divider />
                      <Grid container className={classes.grid} spacing={2}>
                        <Grid item xs={12}>
                          <Grid container justify="center" spacing={2}>
                            {selectedSavedList && renderListView()}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>

                  </div>
                </div>
              </>
            }
          </Paper>
        </div>
      }
    </div>
  );

}
const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    businessData: state.userData.businessData,
    mulitpleWishlistData: state.userData.mulitpleWishlistData,

  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewWishlist: (p) => { dispatch(addNewWishlist(p)) },
    removeNewWishlist: ( ) => { dispatch(removeNewWishlist( )) },
    showToast: (toast: Toast) => { dispatch(enqueueSnackbar(toast)) },
    addToCart: (p) => { dispatch(addToCart(p)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WishList);

function componentDidMount() {
  throw new Error('Function not implemented.');
}
