import {LOG_OUT, SAVE_USER_DATA } from '../actions/actionTypes'
import {  LOGIN_HEADERS,USER_AUTH_TOKEN } from '../../api/auth';

const initialUserDataState={
    isAuthenticated:false,
    userDetails:{},
    // publicTokenVerified:false
}

// const initialUserDataState=null

export default function userAuthData(state = initialUserDataState, action: any) {
    // console.log("inside auth reducer",action.data)
    switch (action.type) {
        case SAVE_USER_DATA:
            return {
                ...state,
                ...action.data,
            }

        case LOG_OUT:
            
            const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': USER_AUTH_TOKEN};
            localStorage.setItem('headers', JSON.stringify(authHeaders));
            return initialUserDataState;
        default: return state;

    }
}