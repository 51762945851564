import React from 'react';
import { AppRegistry } from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
import AppNavigator from './navigation/AppNavigator';
import { Provider } from 'react-redux';
import configureStore from 'components/src/store/configStore.orderFulfillment';
 import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { persistor, store } = configureStore();

if (!__DEV__) {
  console.log = () => { };
}

export const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppNavigator />
     </PersistGate>
   </Provider>
);

AppRegistry.registerComponent('connectors_app', () => App);
AppRegistry.runApplication('connectors_app', {
  rootTag: document.getElementById('root'),
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//   navigator.serviceWorker.register('./serviceworker.js',{scope:'../order-placement/'})
//     .then(function(registration) {  
//       console.log("Service Worker Registered======", registration);
//   })
//   .catch(function(err) {
//       console.log("Service Worker Failed to Register==", err);
//   })
//   });
// } 


// const  loadServiceProvider = async(): Promise <null> => {
//   try{
//   navigator.serviceWorker.register('./serviceworker.js',{scope:'../order-placement/'})
//   .then(function(registration) {  
//     console.log("Service Worker Registered======", registration);
// })
//   }
// catch(err) {
//   console.log("Service Worker Failed to Register==", err);
//   return  
// }
// }


// if ('serviceWorker' in navigator) {
//   console.log("before")
//   document.body.addEventListener('load', loadServiceProvider)
//   console.log("after")
// } 
