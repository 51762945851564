import {
  SET_STOCK_ORDER_DATA, CLEAR_STOCK_ORDER_DATA
} from '../actions/actionTypes';
import cloneDeep from 'lodash/cloneDeep';


const initialState = null;


export default function stockOrderListReducer(state = initialState, action: any) {

  switch (action.type) {

    case SET_STOCK_ORDER_DATA:
      return {
        ...state,
        ...action.data
      }

    case CLEAR_STOCK_ORDER_DATA: return cloneDeep(initialState);
    default:
      return state;
  }
}