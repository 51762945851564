
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IconButton, SvgIcon, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    breadCrumss: {
      color: 'black'
    },
    root: {
      display: 'flex',
      alignItems: 'center'
    }
  }),
);

const BreadCrumbs = (props) => {
  const classes = useStyles({});

  const handleBreadCrumbsButtonClick = (value) => {
    console.log("commoditygroup value", value)
    props.setFilterByCommodityGroup(value.uuid)

  }

  return (
    <div className={classes.root}>
      {props.breadCrumbs.length>0 && <IconButton onClick={props.handeBackBtn}>
        <SvgIcon><ArrowBackIcon /></SvgIcon>
      </IconButton>}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {
          props.breadCrumbs.map((value, index) => {
            // console.log("breadCrumbs",value)
            return (
              <Button key={index} disabled={value.parentCommodityGroup_id === null} size="small" className={classes.breadCrumss} onClick={() => handleBreadCrumbsButtonClick(value)}>
                {value.name}
              </Button>
            )
          })

        }

      </Breadcrumbs>
    </div>
  )
}

export default BreadCrumbs