import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import Typography from '@material-ui/core/Typography';
import { OrderFulfilllmentPaths } from '../../Routes/Routes';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
    mainpaper:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        height:'100%',
        marginTop:'15px',
    },
    innerpaper:{
        width:'justify-content',
        height:'justify-content',
        padding:5,
        margin:'5px',
    },
    heading: {
        display: 'flex',
        width: '100%',
        padding: 10,
        color:GlobalStyles.primaryColor,
    },
    card:{
        margin:4,
    },
      grid:{
        display:'flex',
        flexDirection:'row',
      }

  }),
);  

const OrderDetail=(props:any)=>{
    const classes=useStyles();
    const products=props.history.location.state.items;
    const order=props.history.location.state;
    console.log("inside OrderDetails page",products)
  
    const getDate=(data)=>{
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date=new Date(data)
        let dateToDisplay=date.getDate()+"-"+months[date.getMonth()]+"-"+date.getFullYear()
        let timeToDisplay=date.getHours()+":"+date.getMinutes()
        return<Typography> 
          Order Placed On : {dateToDisplay}    {timeToDisplay}
          </Typography>
    }

  const renderOrderDetails=(products)=>{
  return products.map((product,index)=>{
    return<Card key={index} className={classes.card}>
    <CardContent style={{display:'flex',flexDirection:'row',alignContent: 'center',paddingBottom:'12px'}}>
        <Typography style={{width:'60%',alignContent: 'center'}}>
            {product.productName} 
        </Typography>
        <Typography style={{width:'15%'}}>
        {product.original_ordered_quantity}
        </Typography>
        <Typography style={{width:'15%'}}>
        {product.product_details.prices[0].value.toFixed(2)}
        </Typography>
        <Typography style={{width:'10%'}}>
        {product.baseItemPrice.toFixed(2)}
        </Typography>
    </CardContent>
</Card>
  })

  }
  
  return<div className={classes.root}>
    <Paper elevation={4} className={classes.mainpaper} >
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    <Typography variant="h4" className={classes.heading}>
      Order Details
    </Typography>
    <Typography color="textSecondary" style={{width:'40%',flex:'end'}}>
      Order No - {order.number} 
      {getDate(order.create_time)} 
      Order Total - {order.gross_total}
    </Typography>
    
    </div>
    
    <Paper elevation={4} className={classes.innerpaper} >
<Grid container spacing={3} className={classes.grid}>
<Grid item xs={12}>
    <Card className={classes.card}>
        <CardContent style={{backgroundColor:'#34cceb',display:'flex',flexDirection:'row',justifyContent:'space-between',
        paddingBottom:'12px'}}>
            <Typography style={{width:'60%'}}>
                Product Name 
            </Typography>
            <Typography style={{width:'15%'}}>
                Qty 
            </Typography>
            <Typography style={{width:'15%'}}>
                Price 
            </Typography>
            <Typography style={{width:'10%'}}>
                Total 
            </Typography>
        </CardContent>
    </Card>
    {renderOrderDetails(products)}
 </Grid>
</Grid>
    </Paper>
    </Paper>
</div>
}

export default OrderDetail;