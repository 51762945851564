import React, { useEffect, useState } from 'react';
import WishList from '../../../screens/WishList/WishList';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { SvgIcon } from '@material-ui/core';
import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { SideDrawerMenuItems } from './MenuItemsData'
import { logOutUser } from '../../../data/actions/auth'
import { OrderFulfilllmentPaths } from '../../../Routes/Routes'
import { removeNewWishlist } from '../../../data/actions/multipleWishListAction'
import CategoryIcon from '@material-ui/icons/Category';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import isEqual from 'lodash/isEqual';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../../shared/components/Notifier';
import { PageRedirect } from '../../../../page/components';
import { Children } from 'react';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 300;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    componentroot: {
      display: 'flex',
      // overflow: 'hidden',
    },
    content: {
      height: '100%',
      overflow: 'auto',
      flexGrow: 1,
      marginTop: '64px',
      // backgroundColor: theme.palette.background.default,
      // padding: theme.spacing(3),
    },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      overflow: 'hidden'

    },
    shrinkDrawerWidth: {
      width: 0
    },
    breadCrumss: {
      color: 'black'
    },
    grandparenticon: {
      //paddingLeft: '-40px !important',
      marginLeft: '-10px !important',
      //left: '-40px !important',
    },
    grandparent: {
      //paddingLeft: '-40px !important',
      marginLeft: '-15px !important',
      //left: '-40px !important',
    },
    parent: {
      paddingLeft: 'inherit',
      marginLeft: 'inherit',
      left: '0px !important',
    },
    child: {
      //paddingLeft: '-40px !important',
      marginLeft: '0px !important',
      left: '0px !important',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }),
);

const SideDrawer = (props: any) => {
  const classes = useStyles({});
  const [itemState, setItemState] = useState({});
  const [childPadding, setChildPadding] = useState({});
  const [defaultPadding, setDefaultPadding] = useState(60);
  // useEffect(() => {
  //   setChildPadding(childPadding + 40)
  //   alert(childPadding)
  // }, [ExpandMore]);


  useEffect(() => {
    if (props.history.location.pathname != OrderFulfilllmentPaths.wishList) {
      if (props.history.hasOwnProperty('state')) {
        if (props.history.state.from === OrderFulfilllmentPaths.wishList) {
          if (props.history.state.hasOwnProperty('editedlist')) {
            if (props.history.state.editedlist != null) {
              let editedLists = props.history.state.editedlist
              let savedLists = props.authData.userDetails.saved_list
              !isEqual(editedLists, savedLists) ? props.history.push(OrderFulfilllmentPaths.wishList) : null

            }
          }
        }
      }
    }

    // console.log("history on sideDrawer useeffect",props)  
  }, [props.history.state])


  // console.log("history on sideDrawer ",props)
  const handleClick = (category, parentid) => {

    if (category in itemState) {
      setItemState(prevState => (
        { ...prevState, [category]: !prevState[category] }
      ))
    }
    else {
      setItemState(prevState => (
        { ...prevState, [category]: true }
      ))
    }
    // console.log("Item state===>",itemState)
  }

  const handleSideDrawerItemClick = (subOption: any) => {

    props.setShowBreadCrumbs(true)
    props.setFilterByCommodityGroup(subOption.uuid)
    props.structureBreadCrumsData(subOption)
    props.history.push(subOption.route ? subOption.route  : OrderFulfilllmentPaths.home);
  }

  const handleMenuItemClick = (subOption) => {
    const id = subOption.id
    // console.log("id", id)
    switch (id) {
      case '1':
        if (props.authData.isAuthenticated) {
          //   props.setLoginDialogBox(true)
          // }
          // else {
          const profileUrl = subOption.url

          props.history.state = { ...props.history.state, from: props.location.pathname }
          //props.history.location.state={...props.history.location.state,from:props.location.pathname}
          // console.log("history on profile",props)
          // props.location.state.hasOwnProperty('from')!= undefined ? props.location.state.from=props.location.pathname : {from:props.location.pathname}
          props.history.push(profileUrl);
        }
        break;
      case '2':
        const wishListUrl = subOption.url
        const name = subOption.name
        props.history.location.state = { from: props.location.pathname }
        props.history.push(wishListUrl);
        break;

      case '3':

        props.history.state = { ...props.history.state, from: props.location.pathname }
        props.logOutUser()
        props.removeNewWishlist()
        props.showToast({
          message: 'User Logged Out!',
          options: ToastSuccess,
        })
        props.history.push(OrderFulfilllmentPaths.home);
        break;

      case '4':
        const ordersUrl = subOption.url
        // console.log("id", id)
        // console.log("URL", ordersUrl)
        // console.log("props in switch case 4",props)
        props.history.state = { ...props.history.state, from: props.location.pathname }
        // console.log("history on profile",props.history.state)
        //props.location.state={from:props.location.pathname}
        props.history.push(ordersUrl);

      case '5':
        if (!props.authData.isAuthenticated) {
          props.location.state = { from: props.location.pathname }
          props.history.push(OrderFulfilllmentPaths.signIn);
          props.setLoginDialogBox(true)
          break;
        }


      default:
    }

  }

  const handlPadding = (Child: any) => {
    Child.map((category, index) => {
      if (category.hasChildren && category.parentCommodityGroup_id == null) {
        // console.log("category", category.uuid)
        //console.log("category==>",childPadding)
        const id = category.uuid

        if (category.uuid in childPadding) {

        }
        else {
          setChildPadding(prevState => (
            {
              ...prevState,
              [category.uuid]: defaultPadding
            }
          ))
        }
      }
      if ((category.hasChildren) && (category.parentCommodityGroup_id != null)) {
        // console.log("category", category.uuid)
        if (!(category.uuid in childPadding) && (category.parentCommodityGroup_id in childPadding)) {
          // console.log("parentCommodityGroup_id",childPadding[category.parentCommodityGroup_id]) 
          setChildPadding(prevState => (
            {
              ...prevState,
              [category.uuid]: childPadding[category.parentCommodityGroup_id] + 8
            }
          ))
          // console.log("childpadding", childPadding)
        }
      }
    })
  }


  const handleMenuItems = (data) => {


    return data.map((subOption) => {
      if (!subOption.children) {
        if (subOption.id == '3' && !props.authData.isAuthenticated) { // if user is not logged in , don't show logout in menu
          return
        }
        if (subOption.id == '2' && !props.authData.isAuthenticated) { // if user is not logged in , don't show wishist in menu
          return
        }
        if (subOption.id == '4' && !props.authData.isAuthenticated) { // if user is not logged in , don't show Orders in menu
          return
        }
        if (subOption.id == '1' && !props.authData.isAuthenticated) {
          return
        }
        if (subOption.id == '5' && props.authData.isAuthenticated) {
          return
        }
        const currentPath = props.location && props.location.pathname;
        return (
          <div key={subOption.name}>
            <ListItem
              button
              key={subOption.name}
              selected={
                !subOption.route ? false : currentPath === subOption.route ? true :
                  containsParentRoute(subOption.route, currentPath)
              }
              // className={subOption && subOption.hasChildren == null ? classes.grandparent : ''}
              onClick={() => handleMenuItemClick(subOption)}
            >
              {subOption.id == '1' ?
                <ListItemIcon className={classes.grandparenticon}>
                  <SvgIcon><path d={SVG_ICONS.account} /></SvgIcon>
                </ListItemIcon> : subOption.id == '2' ?
                  <ListItemIcon className={classes.grandparenticon}>
                    <SvgIcon><path d={SVG_ICONS.invoice} /></SvgIcon>
                  </ListItemIcon> : subOption.id == '3' ?
                    <ListItemIcon className={classes.grandparenticon}>
                      <ExitToAppIcon />
                    </ListItemIcon> : subOption.id == '4' ?
                      <ListItemIcon className={classes.grandparenticon}>
                        <SvgIcon><path d={SVG_ICONS.stockOrder} /></SvgIcon>
                      </ListItemIcon> : subOption.id == '5' ?
                        <ListItemIcon className={classes.grandparenticon}>
                          <AccountCircleIcon />
                        </ListItemIcon> : null
              }

              <ListItemText
                className={subOption && subOption.hasChildren == null ? classes.grandparent : ''}

                inset={false}
                primary={subOption.name}
              />
            </ListItem>
          </div>
        )

      }
      return (
        <div key={subOption.name}>
          <ListItem
            button
            // className={classes.grandparent}
            onClick={() => handleClick(subOption.name, subOption.uuid)}
          >
            <ListItemText inset={false} primary={subOption.name} />
            {
              itemState[subOption.name] ? <ExpandLess /> : <ExpandMore />
            }
          </ListItem>
          <Collapse
            in={itemState[subOption.name]}
            timeout="auto"
            unmountOnExit
          >
            {handleChildData(subOption.children)}
          </Collapse>
        </div>
      )
    })
  }

  const containsParentRoute = (routeName: string, subRoute: string) => {
    return subRoute.includes(routeName);
  };

  const handleChildData = (children) => {
    // console.log("data for children", children)
    handlPadding(children)
    const currentPath = props.location && props.location.pathname;
    return children.map((subOption, index) => {
      if (!subOption.children) {
        //console.log("suboption on 304", subOption)
        return (
          <div key={subOption.name}>
            <ListItem
              button
              key={subOption.id}
              selected={
                !subOption.route ? false: currentPath === subOption.route ? true :
                containsParentRoute(subOption.route, currentPath)
              }
              // className={getStylesForSideDrawer(subOption)}
              //className={subOption && subOption.parentCommodityGroup_id == null ? classes.child : classes.child}
              onClick={() => handleSideDrawerItemClick(subOption)}
              >
              {
              }
              <ListItemText
                // className={classes.grandparent}
                style={{ paddingLeft: subOption && subOption.parentCommodityGroup_id == null ? subOption.uuid != 'category123' ? 70 : null : childPadding[subOption.parentCommodityGroup_id] }}
                inset={false}
                primary={subOption.name}
                />
            </ListItem>

          </div>
        )
      }
      return (
        <div key={subOption.name}>
          <ListItem
            button
            // className={getStylesForSideDrawer(subOption)}
            //className={subOption && subOption.parentCommodityGroup_id == null ? classes.grandparent : null}
            
            //style={{ paddingLeft: childPadding[subOption.name] }}
            // className={classes.grandparent}
            selected={
              currentPath === subOption.route ? true :
              containsParentRoute(subOption.route, currentPath)
            }
            onClick={() => handleClick(subOption.name, subOption.uuid)}
            >
            {
              subOption.uuid == 'category123' ?
              <ListItemIcon className={classes.grandparenticon}>
                  <CategoryIcon />
                </ListItemIcon> : null
            }

            <ListItemText
              className={subOption && subOption.parentCommodityGroup_id == null ? classes.grandparent : null}
              style={{ paddingLeft: subOption && subOption.parentCommodityGroup_id == null ? subOption.uuid != 'category123' ? 60 : null : childPadding[subOption.parentCommodityGroup_id] }}
              //style={{ paddingLeft: childPadding[subOption.name] + 30 }}
              inset={false}
              primary={subOption.name} />
            {
              //  console.log("=======>parent ", subOption.name)
            }
            {
              itemState[subOption.name] ?
                <ExpandLess /> :
                <ExpandMore />
                
              }
          </ListItem>
          <Collapse
            in={itemState[subOption.name]}
            timeout="auto"
            unmountOnExit
            >
            {handleChildData(subOption.children)}
          </Collapse>
        </div>
      )
    })
  }
  
  return (
    <Drawer
    className={classes.drawer}
    variant={props.isMobileScreen ? 'temporary' : "persistent"}
    // variant={'temporary' }
    
    anchor="left"
    open={props.openDrawer}
    classes={{
      paper: classes.drawerPaper,
        docked: !props.openDrawer ? classes.shrinkDrawerWidth : undefined
        
      }}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => props.handleDrawer()}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <div style={{ overflow: 'auto' }}>
        <List>
          {handleChildData(props.commodityGroups)}
          {handleMenuItems(SideDrawerMenuItems)}
        </List>
        <Divider />

      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData,
    mulitpleWishlistData: state.userData.mulitpleWishlistData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logOutUser: () => { dispatch(logOutUser()) },
    removeNewWishlist: () => { dispatch(removeNewWishlist()) },
    
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);


