//DEMO
//PAP
import { ADD_NEW_WISHLIST, TOGGLE_PRODUCT, REMOVE_NEW_WISHLIST } from "./actionTypes";


export function addNewWishlist(data) {

  console.log("data in multiple reducer", data)
  return {
    type: ADD_NEW_WISHLIST,
    data
  };
}


export function toggleProduct(data) {

  console.log("data", data)
  return {
    type: TOGGLE_PRODUCT,
    data
  };
}

export function removeNewWishlist() {

  return {
    type: REMOVE_NEW_WISHLIST,
    
  };
}