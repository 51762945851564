import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, 
  Card, CardContent,CardActions, CardMedia, Paper, SvgIcon,RadioGroup, FormControl,
  CircularProgress,Container,ButtonGroup, Radio,FormControlLabel
} from '@material-ui/core'

import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { addToCart, removeFromCart, checkoutEmptyCart, UpdateOrderDetails } from '../../data/actions/cartActions';
import { placeOrder, UserLogin, addOrUpdateCart } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import Login from '../Authentication/Login'
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import DeleteIcon from '@material-ui/icons/Delete';
import { OrderFulfilllmentPaths } from '../../Routes/Routes'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    // card: {
    //   padding: theme.spacing(1),
    //   height: 120,
    //   width: 200
    // },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',

    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    paper: {
      height: 100,
      width: 200,
    },

    root: {
      flexGrow: 1,
      //padding:'18px'
      paddingTop: '18px',
      paddingRight: '18px',
      paddingBottom: '18px'

    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      padding: '4px',
      height: '100%',
      width: '100%',
    },
    button: {
      position: 'relative',
      bottom: '-41px',
      right: '10px'

    },
    cardAction: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '55px',
      marginLeft: '-5px'
    },
    cardContent: {
      flexGrow: 1,
      padding: '2px',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    grid: {
      flexGrow: 1,
      padding: '10px'
    },
    cardImageMobile: {
      height: '80px',
      width: '140px',
      marginLeft: '2px',
      paddingTop: '-2px',
      display: 'flex',
      // marginBottom: 'auto',
      // alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    },
    cardImageWeb: {
      height: '180px',
      width: '400px',
      marginLeft: '2px',
      paddingTop: '-2px',
      display: 'flex',
      // marginBottom: 'auto',
      // alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    },
    total: {
      top: '69px',
      right: '30px',
      position: 'absolute'

    },
    cardActions: {
      paddingTop: '4px'
    },
    container: {

      width: '100%',
      display: 'block',
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '0px',
      paddingRight: '0px'
    }

  }),
);

const checkoutChoices =[
  {
    id : 1,
    value:'customer_order',
    label:'Customer Order'
  },
  {
    id: 2,
    value:'customer_order',
    label:'Stock Order'
  }
]

const Cart = (props) => {
  console.log("cart data", props.userData.cartData)
  const classes = useStyles({});
  const [isLoading, setLoading] = useState(false);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [checkoutButtonClicked, setCheckoutButtonClicked] = useState(false);
  const [loginDialogBox, setLoginDialogBox] = useState(false)

  const [showCheckoutOptions, setShowCheckoutOptions] = useState(false)
  const [selectedChoice, setSelectedChoice] = useState('')




  const handleQuantityIncrement = (p) => {
    console.log("product into cart", p)
    props.addToCart(p)
  }



  const handleQuantityDecrement = (p) => {

    props.removeFromCart(p)

  }

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };

  const getAllProductsImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getImagePath()
    }
  }

  const CheckoutScreen = (data: any) => {
    const checkoutUrl = OrderFulfilllmentPaths.checkout
    //  console.log(productDetailUrl)
    props.history.push(checkoutUrl, data);
  }
  const OrderDeliveryMethodScreen = (data: any) => {
    const orderDeliveryMethodUrl = OrderFulfilllmentPaths.orderDeliveryMethod
    props.history.push(orderDeliveryMethodUrl, data);
  }



  const CartCreateOrUpdate = (order_data) => {
    if (props.userData.cartData.order_id != null) {
      order_data = { ...order_data, 'order_id': props.userData.cartData.order_id }
    }
    addOrUpdateCart(order_data)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        props.showToast({
          message: 'Added to cart Successfully!',
          options: ToastSuccess,
        })
        if (data.order_details) {
          props.UpdateOrderDetails(props.userData.cartData.order_id = data.order_details.order_id)
        }
        // CheckoutScreen(data)
      })

      .catch((error: any) => {
        console.log("inside error", error)
        setLoading(false);
        props.showToast({
          message: 'Unable to Place Order',
          options: ToastError,
        });

        if (error) {
          if (error.status && error.status === 500) {
            setEmptyDataSourceMessage(ERROR_MSG);
            setErrorStatusVisible(false);
          }
        }

      });
  }




  const handleCheckout = () => {
    console.log("props.authData", props.authData)
    // check the business settings from the reducer and check type of authentication required, whether login required or not
    // if login is required then prompt user to login if user is not already logged in
    if (!props.authData.isAuthenticated) {
      props.history.push(OrderFulfilllmentPaths.signIn,{prevPath : OrderFulfilllmentPaths.cart})
      setCheckoutButtonClicked(true)
    }
    else {
      // placeCartOrder()
      CheckoutScreen(null)
    }
  }



  const renderProducts = () => {
    console.log("addedItems", props.userData.cartData)
    return props.userData.cartData.addedItems.map((p, index) => {
      let isSupplierAcc = props.userData.authData && props.userData.authData.isAuthenticated  && props.userData.authData.userDetails.is_default_customer_order 
      let containerQty = p.business_product.article_codes && p.business_product.article_codes[0] &&  p.business_product.article_codes[0].quantity
      return (
        <div key={index}>

          <Grid container className={classes.grid} >
            {/* <Grid item xs={10}> */}
            <Card
              className={classes.card}
              key={index}
            >
              {/* <CardActionArea> */}
              <CardMedia
                // component="img"
                className={props.isMobileScreen ? classes.cardImageMobile : classes.cardImageWeb}
                image={getAllProductsImagePath(p.business_product)}
                // onClick={() => handlePanelProductImageOnClick(p)}
                style={{ backgroundSize: 'contain' }}
              />
              {/* <div className={classes.details}> */}
              <CardContent className={classes.cardContent}>
                <Typography style={{ fontSize: '0.90rem' }}>
                {isSupplierAcc ? p.business_product.name + (` ( x ` + (containerQty ? containerQty : '') + ')')  : p.business_product.name }
                </Typography>

                {!props.businessData.name.includes('Chetak San Francisco') &&
                  <>
                    <Typography style={{ fontSize: '0.90rem' }}>
                      {p.price ? `Price: $${p.price}` : ''}
                    </Typography>
                    <Typography style={{ fontSize: '0.90rem' }}>
                      {p.price ? `Item Total: $${p.quantity_total.toFixed(2)}` : ''}
                    </Typography>
                  </>
                }


                <CardActions className={classes.cardActions}>
                  <ButtonGroup disableElevation variant="contained" color="primary">
                    {p.quantity > 0 &&
                      <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityDecrement(p)}>
                        {p.quantity > 1 ? '-' : <DeleteIcon />}
                      </Button>


                    }

                    {p.quantity > 0 &&
                      <Button disabled>
                        {p.quantity}
                      </Button>
                    }
                    <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityIncrement(p)}>
                      +
                    </Button>
                  </ButtonGroup>
                </CardActions>
              </CardContent>
            </Card>
            {/* </Grid> */}

          </Grid>

        </div>
      )

    })
  }

  const getItemsforStockOrder =(products: any) =>{
    console.log("Items", products)


  }

  const handleChoiceChange = (event: any) => {
    setSelectedChoice(event.target.value);
  };

  const handleCancelCheckoutOption =() =>{
    setSelectedChoice('')
    setShowCheckoutOptions(false)
  }



  const handleSaveAsStockOrder =() =>{
    let payload = getItemsforStockOrder(props.userData.cartData.addedItems)
  }

  return (
    <div style={{ width: '100%' }}>
      {
        isLoading &&
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      }
      {!isLoading &&
        <Container disableGutters={true}>
          {props.userData.cartData.total_items > 0 &&
            <Button size="small" aria-label="small outlined button group" color="primary" onClick={handleCheckout}>
              Checkout
            </Button>
          }
          {props.userData.cartData && props.userData.cartData.total_items != 0 ?
            <Typography className={classes.total} variant="h4">
              {props.userData.cartData.total ? `Total:${props.userData.cartData.total.toFixed(2)}` : `Total`}
              {/* { `Total:${props.userData.cartData.total.toFixed(2)}`} */}

            </Typography>
            :
            ''
          }
          <div style={{
            margin: 'auto',
            paddingTop: 16,
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
            // border: `4px solid`
          }}>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

              <Paper style={{ marginTop: '20px' }}>

                <div>

                  {props.userData.cartData && props.userData.cartData.total_items != 0 ?
                    renderProducts()
                    :
                    <div>
                      <Typography>
                        Cart is empty, let's add some items

                      </Typography>
                    </div>
                  }

                </div>


              </Paper>
            </div>

          </div>

        </Container>
      }
      {/* {showCheckoutOptions &&
        <Dialog
          open={showCheckoutOptions}
          onClose={()=>setShowCheckoutOptions(false)}
        >
          <DialogTitle>
            {`Choose any one of the options `}
          </DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedChoice}
                onChange={(e) => handleChoiceChange(e)}
              >
                <FormControlLabel key={`1`} value={`customer_order`} control={<Radio />} label={`Customer order`} />
                <FormControlLabel key={`2`} value={`stock_order`} control={<Radio />} label={`Stock order`} />

              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="contained" onClick={handleCancelCheckoutOption} >Cancel</Button>
            <Button
              size="small"
              autoFocus
              style={{ backgroundColor: GlobalStyles.primaryColor, textDecorationColor: 'white', color: "#fffde7", }}
              onClick={() => handleSaveAsStockOrder()}  >
              create
            </Button>
          </DialogActions>
        </Dialog>

      } */}
    </div>

  )
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addToCart: (p) => { dispatch(addToCart(p)) },
    removeFromCart: (p) => { dispatch(removeFromCart(p)) },
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    checkoutEmptyCart: () => { dispatch(checkoutEmptyCart()) },
    UpdateOrderDetails: () => { dispatch(UpdateOrderDetails()) }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Cart)
