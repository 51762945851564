import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const USER_AUTH_TOKEN='0f3c75af79f3f314bb273005fbe6010495f6cef2' // Change this for Prod

// window.localStorage.setItem('user_token', '0f3c75af79f3f314bb273005fbe6010495f6cef2');
//if(window.localStorage.getItem('access_token')){
// export const USER_AUTH_TOKEN=window.localStorage.getItem('access_token')

export const API_URLS = {
    // loginUrl: BASE_URL + 'core/auth/login',
    verifyTokenUrl: BASE_URL + 'core/auth/verify_order_app_token',
    getBusinessDetailsUrl: BASE_URL + 'core/auth/get_business_details'
};

export const LOGIN_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'API-KEY': API_CONFIG.API_KEY,
};


export const verifyToken = (token: string,business_url:string) => {
    const data = { token ,business_url};
    console.log("business_url",business_url)
    console.log("data",data)
    const requestHeaders = { ...LOGIN_HEADERS };
    const URL = API_URLS.verifyTokenUrl;
    return fetch(URL, {
        headers: { ...requestHeaders, },
        method: 'POST',
        body: JSON.stringify(data),
    });
};

// export const getBusinessDetails = (business_url:string) => {
//     let data: any
//     if(business_url){
//         data ={ business_url : business_url,  }
//     }
//     let URL = API_URLS.getBusinessDetailsUrl;
//     console.log("HEADERSS", localStorage.getItem("headers") )
//     const requestHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN':USER_AUTH_TOKEN  };
//     return fetch(URL, {
//         headers: { ...requestHeaders, },
//         method: 'POST',
//         body: JSON.stringify(data),
//     });
//   };
  