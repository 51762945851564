import React, { useEffect, useState, useCallback } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {viewCustomerOrders} from '../../api/Api';
import Card from '@material-ui/core/Card';
import Pagination from '@material-ui/lab/Pagination';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { OrderFulfilllmentPaths } from '../../Routes/Routes';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PaginatedResponse } from '../../../shared/models';
import { defaultGridPaginationQuery } from '../../../shared/lib/TableQueryParams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
    mainpaper:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        height:'100%',
        marginTop:'15px',
    },
    innerpaper:{
        width:'justify-content',
        height:'justify-content',
        padding:5,
        margin:'5px',
    },
    heading: {
        display: 'flex',
        width: '100%',
        padding: 10,
        color:GlobalStyles.primaryColor,
      },
    card: {
        Width: '100%',
        margin:5,
        "&:hover": {
            cursor: 'pointer',
          }
          
      },
      title: {
        fontSize: 20,
      },
      grid:{
        display:'flex',
        flexDirection:'row',
      },
      spinner:{
        width:'50%',
        display:'flex',
        flexDirection:'row-reverse',
      },

  }),
);  
const Orders=(props:any)=>{
  const classes=useStyles(); 
  const [orderDetail,setOrderDetail]=useState([])
  const [ordersLoading,setOrdersLoading]=useState(true)
  const pageSize = 10;
  const initalPageData = {
    count: 0,
    next: null,
    previous: null,
    results: []
  } as PaginatedResponse<any>;

  const [pageData, setPageData] = useState(initalPageData);

  const [pagination, setPagination] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSize
  });

  const [navPageNumber, setNavPageNumber] = useState(props.history.location.state);

  const [prevPage, setPrevPage] = useState(1);
  console.log("props in orders",props)

  useEffect(()=>{
    const customer_id= props.authData.userDetails.korona_customer_id
    getOrdersPaginated(customer_id)
  },[])

  const getOrdersPaginated= (customer_id) =>{
  console.log("========>PAGE,PAGESIZE",pagination.page,pageSize)
    viewCustomerOrders(customer_id, pagination.page, pageSize)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      setOrderDetail(data)
      setOrdersLoading(false)
    })
  //   .catch((error: any) => {
  //     console.log("inside error", error)
  // }
  }

  const getDate=(data)=>{
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let date=new Date(data)
      let dateToDisplay=date.getDate()+"-"+months[date.getMonth()]+"-"+date.getFullYear()
      let timeToDisplay=date.getHours()+":"+date.getMinutes()
      return<Typography> 
        Order Placed On : {dateToDisplay}    {timeToDisplay}
        </Typography>
  }
  
  const onPageChange = (e, pageNumber: number) => {
    //    console.log("onPageChange",props.history.location.search);
    let defaultPage = 1
    setPrevPage(pagination.page)
    setPagination({ ...pagination, page: pageNumber });
    if (props.history.location.state === undefined)
      setNavPageNumber(defaultPage)
    else
      setNavPageNumber(props.history.location.state)
  };


    const renderOrders=(orders)=>{
        console.log(orders.length)
        if(orders.length ==0)
          return(
          <Typography className={classes.title} color="textSecondary">
         No Orders Placed Yet !!!
        </Typography>
        )
        else return orders.map((order,index)=>{
        return(
        <Card key={index} className={classes.card}onClick={()=>navigateToOrderDetailScreen(order)}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Order No : {order.number}
          </Typography>
          {/* <Typography>
            Date : {getDate(order.create_time)}   Time : 5.30.02
          </Typography> */}
          <Typography  >
          {getDate(order.create_time)}
            Total Products : {order.items.length}
          </Typography>
          <Typography  >
            Order Total : {order.gross_total}
          </Typography>
          <Typography >
            Order Status : {order.order_status}
          </Typography>
        </CardContent>
      </Card>
      )
    })
}
    const navigateToOrderDetailScreen = (items) => {
        const orderDetailUrl = OrderFulfilllmentPaths.orderDetail
        props.history.push(orderDetailUrl,items);
      }



    return( 
    <div className={classes.root}>
        <Paper elevation={4} className={classes.mainpaper} >
        <Typography variant="h5" className={classes.heading} >
          Your Orders
        </Typography>
            <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} sm={12}>
                <div>
                {ordersLoading ? (<div  className={classes.spinner}><CircularProgress /></div> ) : renderOrders(orderDetail)}
                </div>
            </Grid>
            </Grid>
            {/* <Pagination
              boundaryCount={2}
              count={Math.ceil(pageData.count / pageSize)}
              size={props.isMobileScreen ? "small" : "medium"}
              showFirstButton showLastButton
              style={{ display: 'flex', padding: 0, flexWrap: 'wrap', listStyle: 'none', justifyContent: 'center' }}
            /> */}
            {/* <Pagination count={10} /> */}
        </Paper>
    </div>
     )
}
const mapStateToProps = (state: any) => {
  return {
    authData: state.userData.authData
  };
};

export default connect(mapStateToProps)(Orders);