import {ADD_CART,REMOVE_CART,CHECKOUT_AND_EMPTY_CART,UPDATE_ORDER_DETAILS, RESET_CART } from '../actions/actionTypes'


const initialCartState = {
    addedItems:[],
    total:0,
    total_items:0,
    order_id:null

};


export default function cartDataReducer(state = initialCartState, action: any) {
    // console.log("inside cart reducer",action.data)
    switch (action.type) {
        case ADD_CART:
            
            let existing_item = state.addedItems.find(item=>item.uuid===action.data.uuid)
            

            if(existing_item){
            existing_item.quantity+=1    
            existing_item.quantity_total=existing_item.quantity_total+existing_item.price
            
            return (action.data.price ?{
                    ...state,
                    total:state.total+action.data.price
                }:{
                    ...state,
                    total:state.total
                })

            }
            else{
                console.log("action.data",action.data)
                action.data.quantity+=1
                action.data.quantity_total=action.data.price
            //     // let newTotal=state.total+addedItem.price
                        return (action.data.price? {
                ...state,
                addedItems:state.addedItems.concat(action.data),//use concat instead of push when mutating the state
                total_items:state.total_items+1,
                total:state.total+action.data.price
                // total:parseFloat(state.total+action.data.price)
            }:{...state,
            addedItems:state.addedItems.concat(action.data),//use concat instead of push when mutating the state
            total_items:state.total_items+1,
            total:state.total})


        }
        case REMOVE_CART:
            console.log("remove from card trigged")
            
            let itemToRemoveExists=state.addedItems.find(item=>item.uuid===action.data.uuid)
            if (itemToRemoveExists){
                if (itemToRemoveExists.quantity <=1){//if item has quantity remove from cart
                    itemToRemoveExists.quantity=0
                    itemToRemoveExists.quantity_total=0

                let newItems=state.addedItems.filter(item=>item.uuid!==action.data.uuid)
               
                                return (action.data.price?{
                    ...state,
                    addedItems:newItems,
                    total_items:state.total_items-1,
                    total:state.total-action.data.price
                }:{ ...state,
                    addedItems:newItems,
                    total_items:state.total_items-1,
                    total:state.total})
                }
                else{
                // calculate price and remove it by finding ItemToRemove's price & decrease quantity
                    itemToRemoveExists.quantity-=1
                    
                    itemToRemoveExists.quantity_total=itemToRemoveExists.quantity_total-itemToRemoveExists.price
                    return (action.data.price?{
                        ...state,
                        addedItems:state.addedItems,
                        total:state.total-action.data.price
                    }:{...state,
                        addedItems:state.addedItems,
                        total:state.total})
                }
            }

            case CHECKOUT_AND_EMPTY_CART:
                console.log("inside checkout and empty cart")
                return initialCartState;
            
            case UPDATE_ORDER_DETAILS:
                return{
                    ...state,
                    ...action.data
                }
                case RESET_CART:
                    return initialCartState
                        
            
            

        default:  return state;

    }
    
}


