import React, { useEffect, useState, useRef, } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography, Snackbar, FormControl, InputLabel, Checkbox,
  IconButton, Menu, MenuItem, Grid, CircularProgress,SvgIcon,
  Paper, Container, TablePagination, Divider, Button
} from '@material-ui/core';
import GlobalStyles from '../../../../../styles/GlobalStyles.web';
// import {ImageActions } from '../../models';
import { Toast, enqueueSnackbar, ToastError, ToastSuccess } from '../../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../../shared/lib/Localization';

import MaterialTable, { MTableToolbar } from 'material-table';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../../shared/lib/TableQueryParams';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../../shared/components';
import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { OrderFulfilllmentPaths } from '../../../Routes/Routes';
import cloneDeep from 'lodash/cloneDeep';
import { checkStatus, parseJSON, handleError } from '../../../../shared/api/core/common';
import { matchOrderedProducts } from '../../../api/Api'
import {
  saveStockOrderData, clearStockOrderData
} from '../../../data/actions/stockOrderData';

import { checkoutEmptyCart } from '../../../data/actions/cartActions';
import { createStockOrder } from '../../../api/Api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },

  })
)

const VerifyDataScreen = (props: any) => {

  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [orderedData, setOrderedData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [ stockOrderCreated, setStockOrderCreated] = useState(false)

  useEffect(() => {
    console.log("Props", props)
  }, [props])

  useEffect(() => {
    if (props.location) {
      if (props.location.pathname === OrderFulfilllmentPaths.verifyStockOrderData) {
        if (props.userData) {
          if(!props.soData){
            window.location.href = OrderFulfilllmentPaths.home
          }else{
            if (!orderedData) {

              let payload = { data: props.userData.soData, business_id: props.businessData.id , customer_business_id: props.authData.userDetails.user_business_info.id}
  
              setLoading(true);
              matchOrderedProducts(payload)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((data: any) => {
                  setLoading(false);
                  console.log("Api----", data)
                  setOrderedData(cloneDeep(data.stockOrderItemList))
  
                })
                .catch((error: any) => {
                  setLoading(false);
                  props.showToast({
                    message: 'Failed.',
                    options: ToastError
                  });
                });
  
            }
          }
          
        }
      }
    }
  }, [props])



  const renderTable = (orderDetails: any) => {

    console.log("TableData", props)
    console.log("DATAAAAAAAAAAAAAAAA", orderDetails)

    if (orderDetails) {
      console.log("test------------")
      let reOrderCodeValid = props.authData.userDetails.is_reorder_code_valid
      let orderedItems = orderDetails.addedItems
      let grandTotal = orderDetails.total
      let updatedItems: any
      // let sNoExists = orderedItems.some(i=>i.s_no)
      
      // if(!sNoExists){
      //   updatedItems= orderedItems.filter((i, index) => i.s_no = index + 1)
      // }
      // else{
      //   updatedItems = orderedItems
      // }


      console.log("updatedItems", orderedItems, updatedItems)
      return (
        <div className="table-wrapper" style={{ maxWidth: '100%', padding: 15 }}>
          <MaterialTable

            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              search: false
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {
                title: 'S.No',
                field: 's_no',
                align: 'left',
                render: rowData => (rowData.s_no ?
                  <div style={{ maxWidth: 10 }}>{rowData.s_no}</div>
                  : <div>---</div>
                ),
              },
              {
                title: 'Item.No',
                field: 'business_product',
                align: 'left',
                render: rowData => (rowData.business_product.article_codes ?
                  <div>{rowData.business_product.article_codes[0].code}</div>
                  : <div>---</div>
                ),
                hidden: !reOrderCodeValid
              },
              {
                title: 'Description',
                field: 'business_product',

                render: rowData => (rowData.business_product.name ?
                  <div style={{ minWidth: 350 }} >{rowData.business_product.name + ` ( x ` + rowData.business_product.article_codes[0].quantity + `)`}</div>
                  : <div>---</div>
                ),
              },
              {
                title: 'Quantity',
                field: 'quantity',
                align: 'right',
                render: rowData => (rowData.quantity ?
                  <div>{rowData.quantity}</div>
                  : <div>---</div>
                ),
              },
              {
                title: 'Rate',
                field: 'price',
                align: 'right',
                render: rowData => (rowData.price ?
                  <div>{rowData.price}</div>
                  : <div>---</div>
                ),
              },

              {
                title: 'Amount',
                field: 'quantity_total',
                align: 'right',
                render: rowData => (rowData.quantity_total ?
                  <div>{rowData.quantity_total}</div>
                  : <div>---</div>
                ),
              },
              {
                title: 'Status',
                field: 'status',
                align: 'right',
                editable: 'never',
                render: (rowData: any) => {
                  const { error } = rowData;
                  if (error === '') {
                    return <CheckCircleIcon style={{ color: GlobalStyles.green }} />;
                  }
                  return <CancelIcon style={{ color: GlobalStyles.red }} />;
                },
                // hidden: 
              }

            ]}
            data={orderedItems}
            title={
              <CustomTableTitle
                // history={props.history}
                title={`Ordered Items`}
              // icon={SVG_ICONS.product} 
              />
            }
            // components={{ Pagination: () => <div>Total: {grandTotal} </div> }} 
            components={{
              Pagination: (props) => <>
                <Grid container style={{ padding: 15 }}>
                  <Grid sm={6} item><Typography variant="subtitle2">{`Total `}</Typography></Grid>
                  <Grid sm={6} item ><Typography variant="subtitle2" align='right'>{grandTotal}</Typography></Grid>
                </Grid>
                <Divider />
                <TablePagination {...props} />
              </>
            }}
            actions={[
              {
                icon: () => <SvgIcon>
                  <path d={SVG_ICONS.createStockOrder} />
                </SvgIcon>,
                tooltip: 'Create Stock Order',
                isFreeAction: true,
                onClick: () => saveStockOrder(orderedItems, grandTotal),
              },
            ]}

          />

        </div>
      )
    }
  }

  const saveStockOrder = (items: any, total: any) =>{
    console.log("Items", items, total)
    setLoading(true)
    let orderedItems = cloneDeep(items && items.filter(i=>i.error === ''))
    console.log("Updated", orderedItems)
    let updatedList: any =[]
    
    for (let i = 0; i < orderedItems.length; i++) {
      let obj ={}
      obj["itemIndex"] = i + 1,
      obj["name"] = orderedItems[i]["business_product"]["name"]
      obj["supplierItemNumber"] = orderedItems[i]["reOrderCode"]
      obj["actualGoods"] = (Number(orderedItems[i]["master_supplier_container_qty"]) * orderedItems[i]["quantity"])
      obj["articleNumber"] = orderedItems[i]["product"] ? orderedItems[i]["product"]["number"] : null
      obj["article"] = orderedItems[i]["product"]["uuid"]
      obj["code"] = null,
      obj["color"] = null,
      obj["size"] = null,
      obj["quantity"] = (Number(orderedItems[i]["master_supplier_container_qty"]) * orderedItems[i]["quantity"]),
      obj["bookedQuantity"] = null,
      obj["purchasePrice"] = null,
      obj["salesPrice"] = null,
      obj["desiredOverallQuantity"] = (Number(orderedItems[i]["master_supplier_container_qty"]) * orderedItems[i]["quantity"]),
      obj["actualOverallQuantity"] = null,
      obj["receivedQuantity"] = null,
      obj["containerQuantity"] = Number(orderedItems[i]["master_supplier_container_qty"]),
      obj["itemPrice"] = Number(orderedItems[i]["price"]) / Number(orderedItems[i]["master_supplier_container_qty"]);
      obj["deleted"] = false
      updatedList.push(obj)
    }



    let payload ={ 
      supplier: props.userData.authData.userDetails.linked_supplier_info.korona_supplier.uuid,
      target: props.userData.authData.userDetails.user_business_info.organizational_unit[0].uuid,
      items:updatedList,
      location : props.userData.authData.userDetails.user_business_info.organizational_unit[0].location,
      description:''
    }
    console.log("Payload", payload)

    createStockOrder(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data:any| null) => {
        console.log('createStockOrder success', data);
        props.clearSoData()
        props.checkoutEmptyCart()
        props.showToast({ message: 'Stock Order created successfully!', options: ToastSuccess });
        setLoading(false)
        setStockOrderCreated(true)

      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false)
        let msg = 'Failed to create Stock Order!';
        props.showToast({ message: msg, options: ToastError });;
      });


    
  } 


  return (
    <div>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading && !stockOrderCreated &&
        orderedData && renderTable(orderedData)
        
      }
      {
        
        !isLoading && stockOrderCreated && setTimeout(() => (window.location.href = OrderFulfilllmentPaths.home), 4000) &&
        
        <Paper style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">
            Stock order created successfully! Redirecting to Home Screen...
          </Typography>
        </Paper>
        
      }
    </div>
  )


}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    cartData: state.userData.cartData,
    soData: state.userData.soData,
    businessData: state.userData.businessData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setStockOrderData: (order: any) => dispatch(saveStockOrderData(order)),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    clearSoData :() => { dispatch(clearStockOrderData())},
    checkoutEmptyCart: () => { dispatch(checkoutEmptyCart()) },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyDataScreen);