import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { checkoutEmptyCart } from '../../data/actions/cartActions';
import { placeOrder } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import { OrderFulfilllmentPaths } from '../../Routes/Routes'

import {
  saveStockOrderData, clearStockOrderData
} from '../../data/actions/stockOrderData';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&:hover': {
        cursor: "pointer"
      }
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
  })
)

const Checkout = (props: any) => {
  console.log("inside  checkout", props)
  const classes = useStyles({});
  const [cartData, setCartData] = useState(null as any)
  const [orderSuccessful, setOrderSuccessful] = useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const [emptyCart, setEmptyCart] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false)
  const [cancelStockOrder,setCancelStockOrder] = useState(false)

  useEffect(() => {
    console.log("inside useEffect of checkout")
    // setCartData(props.userData.cartData)
    let selectedLocation = props.businessData.organizational_unit.filter((obj) => (obj.isSelected == true))
    if (selectedLocation.length == 0) {
      props.showToast({
        message: 'Please select a location to place order',
        options: ToastError,
      })
      props.history.push(OrderFulfilllmentPaths.cart)
    }
    else {
      if (!(props.authData && props.authData.userDetails.is_default_customer_order != null && props.authData.userDetails.is_default_customer_order === true)) {
        setEmptyCart(true)
      }
      if (selectedLocation.length == 1 && props.userData.cartData.total_items > 0) {
        placeCartOrder()
      }
    }
  }, [])

  useEffect(() => {
    console.log("props.userData changed", props.cartData)
  }, [props.cartData])

  const getTaxAmount = (data: any, basePrice: any) => {
    let taxItems = data.tax.map(i => i);
    let taxAmount = 0


    for (let i = 0; i < taxItems.length; i++) {
      if (taxItems[i].included === false) {
        if (taxItems[i].latest_rate) {
          taxAmount += (taxItems[i].latest_rate['rate'] * basePrice) / 100;

        }

      }
      if (taxItems[i].included) {
        let includedTaxRateTotal = 0
        includedTaxRateTotal += taxItems[i].latest_rate['rate']
        let netTotal = basePrice / (1 + includedTaxRateTotal / 100)

        let latestRateTotal = (taxItems[i].latest_rate['rate'] * netTotal) / 100;


        taxAmount += latestRateTotal;

      }

    }

    return taxAmount
  }

  const getNetTotal = (data: any, basePrice: any) => {

    // console.log("SECTOR",data)
    console.log(" base PRICE", basePrice)
    let taxItems = data.tax.map(i => i);
    let netTotal = 0
    let includedTaxRateTotal = 0
    for (let i = 0; i < taxItems.length; i++) {
      if (taxItems[i].included) {

        // console.log("latest rate",taxItems[i].latest_rate['rate'])
        includedTaxRateTotal += taxItems[i].latest_rate['rate'] // add all tax %
      }
    }

    netTotal = basePrice / (1 + includedTaxRateTotal / 100)
    return netTotal
  }


  const getGrossAmount = (data: any, basePrice: any) => {
    let taxItems = data.tax.map(i => i);
    let grossAmount = 0
    let taxAmount = 0
    for (let i = 0; i < taxItems.length; i++) {
      if (taxItems[i].included === false) {
        if (taxItems[i].latest_rate) {
          taxAmount += (taxItems[i].latest_rate['rate'] * basePrice) / 100;


        }

      }
    }

    grossAmount = basePrice + taxAmount
    return grossAmount
  }

  // const checkoutAndPlaceOrder = (order_data) => {
  //   console.log("before setting setCartData", props.userData.cartData)
  //   setCartData(props.userData.cartData)
  //   setOrderSuccessful(true)
  //   props.checkoutEmptyCart()

  // }

  const checkoutAndPlaceOrder = (order_data) => {

    placeOrder(order_data)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setOrderSummary(data)
        props.showToast({
          message: 'Order Placed Successfully!',
          options: ToastSuccess,
        })
        console.log("cartData", props.userData.cartData)
        setCartData(props.userData.cartData)
        setOrderSuccessful(true)
        props.checkoutEmptyCart()
        setOrderSuccessful(true)
        props.clearSoData()
        props.setStockOrderData(props.userData.cartData)
      })

      .catch((error: any) => {
        console.log("inside error", error)
        // setLoading(false);
        props.showToast({
          message: 'Unable to Place Order',
          options: ToastError,
        });



      });
  }


  const placeCartOrder = () => {
    console.log("inside checkout file place order")
  
    // console.log("props.businessData",props.userData.businessData)
    const cart_data = props.userData.cartData.addedItems
    const data_to_send = { business: { id: null }, customer: { korona_customer_uuid: '', customer_id: '' }, user: { name: '', phone: '', id: 1 }, ordered_items: [], location: { korona_org_unit: '', location_id: '' }, gross_total: 0 }
    const business_id = props.businessData.id
    let selectedLocation = props.businessData.organizational_unit.filter((obj) =>(obj.isSelected == true))
    const korona_org_unit = selectedLocation[0].uuid // CHANGE THIS 
    const locationId = selectedLocation[0].location // CHANGE THIS
    // const korona_org_unit = koronaOrgId

    // console.log("Lcaotion id ====>",location)
     //const locationId = location
    data_to_send.user.name = props.authData.userDetails.first_name + ' ' + props.authData.userDetails.last_name
    data_to_send.user.phone = null
    // data_to_send.user.email=props.authData.email
    data_to_send.location.korona_org_unit = korona_org_unit

    data_to_send.location.location_id = locationId
    data_to_send.business.id = business_id
    data_to_send.customer.korona_customer_uuid = props.authData.userDetails.korona_customer_id
    data_to_send.customer.customer_id = props.authData.userDetails.id
    cart_data.forEach(element => {
      const ordered_items = { id: '', name: '', commodityGroup: {}, quantity: null, sector: {}, gross: null, base: null, discount: null, net_total: null, tax_amount: null }
      ordered_items.id = element.business_product.uuid
      ordered_items.name = element.business_product.name
      ordered_items.commodityGroup = element.business_product.commodityGroup
      ordered_items.sector = element.business_product.sector
      ordered_items.gross = (element.price ? getGrossAmount(element.business_product.sector, element.price) * element.quantity : 0)
      ordered_items.base = (element.price ? element.price * element.quantity : 0)
      ordered_items.discount = 0
      ordered_items.net_total = (element.price ? getNetTotal(element.business_product.sector, element.price) * element.quantity : 0)
      ordered_items.tax_amount = (element.price ? getTaxAmount(element.business_product.sector, element.price) * element.quantity : 0)
      ordered_items.quantity = element.quantity
      data_to_send.ordered_items.push(ordered_items)
      data_to_send.gross_total = props.userData.cartData.total.toFixed(2)
    })
    console.log("before calling checkout And Place Order", props.userData.cartData)
    // setOrderSuccessful(true)
  //  props.checkoutEmptyCart()
  //   navigateToHome()
    checkoutAndPlaceOrder(data_to_send)

  }

  const navigateToHome = () => {
    setRedirectToHome(true)
    setOrderSuccessful(false)
    setTimeout(() => {
      const homeUrl = OrderFulfilllmentPaths.home
      props.history.push(homeUrl)
    }, 2000)

  }


  return (

    //set loading here 
    <div style={{ padding: 10 }}>
      <div style={{ maxWidth: '100%' }}>
        {redirectToHome &&
          <div className={classes.rowCenter}>
            <Typography variant="subtitle1" style={{ padding: 10 }}>
              Redirecting to home page
              {<CircularProgress
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: 15,
                  marginTop: 7
                }} />
              }
            </Typography>
          </div>
        }




        {orderSuccessful &&
          <Paper style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4">
            Order Details

          </Typography>
          <Typography variant="h6" style={{paddingLeft:10}}>
            Order Number : {orderSummary.number}

          </Typography>

          <Typography variant="h6" style={{paddingLeft:10}}>
            Order Total  : {cartData.total.toFixed(2)}
          </Typography>
          <Typography variant="h6" style={{paddingLeft:10}}>
            Items Ordered  : {cartData.total_items}
          </Typography>
          {cartData && cartData.taxDetails &&
            cartData.taxDetails.map((item) => {
              <Typography variant="h6" style={{paddingLeft:10}}>
                Tax Applied  : {item.name}
                Tax Amount :{item.taxAmount}
                Tax Rate:{item.latest_rate.rate}
              </Typography>
            })
          }
            <div style={{ padding: 10, display: 'flex', }}>
              <Typography variant="h6" >
                Thank you for ordering! To redirect to home
              </Typography>
              <Button variant="contained"
                className={classes.button}
                style={{
                  marginLeft: 20,
                  borderRadius: 4,
                  alignSelf: 'center',
                  color: 'black',
                  backgroundColor: GlobalStyles.chipBackground
                }}
                onClick={navigateToHome}
              >
                Click here
              </Button>
            </div>

            {!cancelStockOrder && props.authData && props.authData.userDetails.is_default_customer_order != null && props.authData.userDetails.is_default_customer_order === true &&
              <div style={{ padding: 10, display: 'flex', }}>
                <Typography variant="h6" >
                  As you are a business customer, do you wish to import as a stock order ?
                </Typography>
                <Button variant="contained"
                  color="primary"
                  style={{
                    marginLeft: 20,
                    borderRadius: 4,
                    alignSelf: 'center',
                  }}
                  onClick={() => props.history.push(OrderFulfilllmentPaths.verifyStockOrderData)}
                >
                  Create
                </Button>
                <Button variant="contained"

                  style={{
                    marginLeft: 20,
                    borderRadius: 4,
                    alignSelf: 'center',
                    color: 'black',
                    backgroundColor: GlobalStyles.chipBackground
                  }}
                  onClick={()=>setCancelStockOrder(true)}
                >
                  Cancel
                </Button>
              </div>
            }



          </Paper>
        }
      </div>
    </div>
  )

}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData,
    cartData: state.userData.cartData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // UpdateTaxDetails:(data)=>{dispatch(UpdateTaxDetails(data))},
    setStockOrderData: (order: any) => dispatch(saveStockOrderData(order)),
    clearSoData: () => { dispatch(clearStockOrderData()) },
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    checkoutEmptyCart: () => { dispatch(checkoutEmptyCart()) },

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout)

