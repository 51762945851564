import {SET_BUSINESS_DETAILS } from '../actions/actionTypes'

const initialBusinessDataState={
    id:null,
    organizational_unit:[],
    name:null

};


export default function businessData(state = initialBusinessDataState, action: any){
    // console.log("inside business reducer")
    switch (action.type) {    
        case SET_BUSINESS_DETAILS:
            return{
                ...state,
                ...action.data
            }
        default: return state;

    }
}