
export const OrderFulfilllmentPaths={
    // home:'/order-placement/:id',
    home:'/store',
    productDetail:'/store/product/',
    cart:'/store/cart',
    profile:'/store/profile',
    checkout:'/store/checkout',
    orderDeliveryMethod:'/store/orderDeliveryMethod',
    wishList:'/store/wishList',
    signIn:'/store/signIn',
    orders:'/store/orders',
    orderDetail:'/store/orderdetail',
    verifyStockOrderData: '/store/stock-order/verify-data',
}

