import { SET_STOCK_ORDER_DATA, CLEAR_STOCK_ORDER_DATA } from './actionTypes'


export const saveStockOrderData = (data: any) => {
  return {
      type: SET_STOCK_ORDER_DATA,
      data
  };
};

export const clearStockOrderData =()=> {

  return {
    type: CLEAR_STOCK_ORDER_DATA,
    
  };
}