import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import OrderFulfillmentLayout from 'components/src/modules/order-fulfillment/layout/OrderFulfillmentLayout/OrderFulfillmentLayout';
import { NotFound404 } from 'components/src/modules/reference-catalog/components/NotFound404/NotFound404';

import { Theme, createStyles, makeStyles, } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Notifier } from 'components/src/modules/shared/components/';
import { verifyToken, LOGIN_HEADERS, USER_AUTH_TOKEN } from '../../../../packages/components/src/modules/order-fulfillment/api/auth'
import { handleError, checkStatus, parseJSON } from '../../../components/src/modules/shared/api/core';
import { setBusinessDetails } from '../../../components/src/modules/order-fulfillment/data/actions/businessActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TrendingUpTwoTone } from '@material-ui/icons';
import { checkoutEmptyCart, resetCart } from 'components/src/modules/order-fulfillment/data/actions/cartActions';
import { logOutUser } from 'components/src/modules/auth/data/actions';
const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#ffb300',
		},
	},
});
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			width: '50%',
			paddingTop: '20%',
			display: 'flex',
			flexDirection: 'row-reverse',
		},
	}),
);

const isUserAuthenticated = () => {
	return window.localStorage.getItem('headers') && window.localStorage.getItem('persist:root');
};

// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', function() {
// 	  navigator.serviceWorker.register('/service-worker.js');
// 	});
//   }



const AppNavigator = (props: any) => {
	const classes = useStyles({});
	const [businessID, setBusinessID] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [bussinessData, setBussinessData] = useState(null);

	useEffect(() => {

		//		localStorage.clear()
		if (localStorage.getItem("public_token_verified") === "true") {
			let root = JSON.parse(localStorage.getItem("persist:root"))
			let useData = JSON.parse(root.userData)
			console.log("USER DATA", useData)
			setLoading(true)
			verifyToken(USER_AUTH_TOKEN, window.location.href)
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then((data) => {
					// console.log("data.bussiness id", data)
					// console.log("userdata.bussiness id", data.business.id)
					if(data.business.organizational_unit.length == 1){
						data.business.organizational_unit.map((obj)=>(obj['isSelected']=true ))		
						console.log("data.bussiness====>",data.business.organizational_unit)	
					}else{
					data.business.organizational_unit.map((obj)=>(obj['isSelected']=false ))
					}
					if (data.business.id != useData.businessData.id) {
						try {
							console.log("HERE====>", data)
							//localStorage.removeItem("persist:root")
							//props.logOutUser()
							props.resetCartData()
							//clearLocal()
							//props.checkoutEmptyCart()
							localStorage.clear()
							const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
							//const publicTokenHeaders = { 'public_token_verified': true }

							// const favicon = document.getElementById("favicon") as HTMLAnchorElement
							// favicon.setAttribute("href", "https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico");
							//favicon.href = " https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico";
							props.setBusinessDetails(data.business)
							localStorage.setItem('headers', JSON.stringify(authHeaders));
							localStorage.setItem('public_token_verified', JSON.stringify(true));
							setLoading(false)
							//  business_id=data.business.id
						} catch (e) {
							console.log('could not store in localStorage');
							console.log("e is printed", e)
							setLoading(false)
						}
						console.log("USER DATA", props.userData)
					}
				})
			setLoading(false)
		} 
		else if (localStorage.getItem("public_token_verified") === null) {
			setLoading(true)
			verifyToken(USER_AUTH_TOKEN, window.location.href)
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then((data) => {
					console.log("HERE====>", data)
					const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
					const publicTokenHeaders = { 'public_token_verified': true }
					// const favicon = document.getElementById("favicon") as HTMLAnchorElement
					// favicon.setAttribute("href", "https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico");

					//favicon.href = " https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico";
					// props.saveUserData(data);
					try {

						console.log("data.bussiness====>",data)
						localStorage.setItem('headers', JSON.stringify(authHeaders));
						localStorage.setItem('public_token_verified', JSON.stringify(true));
						props.setBusinessDetails(data.business)
						setLoading(false)
						//  business_id=data.business.id
					} catch (e) {
						console.log('could not store in localStorage');
						console.log(e)
						setLoading(false)
					}

				})

		}
		// const setFaviconEl=(logourl)=>{
		// 	const favicon=document.getElementById("favicon");
		//     favicon.href = "https://www.google.com/favicon.ico";
		// }

		// favicon.src = img.src
	}, []);

	useEffect(()=>{
		if(props.userData && props.userData.businessData.image_data != null){
		console.log("USERDATA", props.userData.businessData.image_data[0].media_core.source)
		const favicon = document.getElementById("favicon") as HTMLAnchorElement;
		favicon.href=props.userData.businessData.image_data[0].media_core.source;
		}
		document.title = props.userData.businessData.name;
	},[props.userData])

	// const clearLocal = async () => {
	// 	try {
	// 		await localStorage.clear();
	// 		console.log("CLEAR CALLED")

	// 	} catch {
	// 		 console.log('error in localStorage');
	// 	}
	// }; 

	return (
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<Notifier />

				{isLoading ? <div className={classes.spinner}><CircularProgress /></div> :
					(<Router>
						<Switch>
							<Route path="/" render={() => <OrderFulfillmentLayout />} />
							<Route path="*" component={NotFound404} />
						</Switch>
					</Router>)
				}

			</SnackbarProvider>
		</MuiThemeProvider>
	);
};


const mapStateToProps = (state: any) => {
	return {
		userData: state.userData,
		authData: state.userData.authData,
		businessData: state.userData.businessData,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setBusinessDetails: (data) => dispatch(setBusinessDetails(data)),
		checkoutEmptyCart:()=>(checkoutEmptyCart()),
		logOutUser:()=>dispatch(logOutUser()),
		resetCartData:()=>dispatch(resetCart()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigator);
