import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Typography } from '@material-ui/core';
import Grid, { GridSpacing } from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import { Theme, createStyles, makeStyles, } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { EditUserProfile } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import TextField from '@material-ui/core/TextField';

import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';


import { saveUserLoginData } from '../../data/actions/auth';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    baseCard: {
      margin: 10,
      marginTop:15,
    }
  })
)

const Profile = (props: any) => {
  console.log("inside profile")
  const classes = useStyles({});
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [userDetails, setUserDetails] = useState(null)
  const [updatedUserDetails, setUpdatedUserDetails] = useState(null);
  // const [refreshUserDetails,setRefreshUserDetails]=useState(false);


  useEffect(() => {
    setUserDetails(props.authData.userDetails)
    setUpdatedUserDetails({ id: props.authData.userDetails.id })
    // setRefreshUserDetails(false)
  }, [])

  // useEffect(() => {
  //     if (refreshUserDetails==true){

  //     // setTimeout(() => {location.reload();}, 3000);
  //     }
  // }, [refreshUserDetails])

  const handleSaveUserDetails = () => {
    console.log("data to send", updatedUserDetails)
    EditUserProfile(updatedUserDetails)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        console.log("response from api", data)
        const data_to_store = {
          isAuthenticated: true,
          userDetails: data
        }
        props.saveUserLoginData(data_to_store)
        props.showToast({
          message: 'Profile Updated Successfully!',
          options: ToastSuccess,
        });
        // setRefreshUserDetails(true)

      })
      .catch((error: any) => {
        console.log("inside error", error)
        // setLoading(false);
        props.showToast({
          message: 'Unable to Update Profile',
          options: ToastError,
        });

        if (error) {
          if (error.status && error.status === 500) {
            // setEmptyDataSourceMessage(ERROR_MSG);
            // setErrorStatusVisible(false);
          }
        }

      });
  }



  const handleFirstNameChange = (name: string) => {
    console.log("userDetails", userDetails)
    console.log("inside handle name change", name)
    setUserDetails({ ...userDetails, first_name: name })
    setUpdatedUserDetails({ ...updatedUserDetails, first_name: name });
  };

  const handleLastNameChange = (name: string) => {
    console.log("inside handle name change", name)
    setUserDetails({ ...userDetails, last_name: name })
    setUpdatedUserDetails({ ...updatedUserDetails, last_name: name });
  };

  const handleEmailChange = (email: string) => {
    setUserDetails({ ...userDetails, email: email })
    setUpdatedUserDetails({ ...updatedUserDetails, email: email });
  };

  const handleUserNameChange = (username: string) => {
    setUserDetails({ ...userDetails, username: username })
    setUpdatedUserDetails({ ...updatedUserDetails, username: username });
  };

  return (
    <div>
      {!props.authData.isAuthenticated &&
        props.setLoginDialogBox(true)
      }
      {props.authData.isAuthenticated &&
        <Card className={classes.baseCard}>
          <div style={{ marginLeft: 10 ,marginBottom:10 }}>
            <div>
              <Typography variant='h5'
                className={classes.title}>My Profile</Typography>
            </div>

            <Grid container className={classes.row}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '30%' }}>

                <TextField
                  id="first_name"
                  className={classes.textFieldCardRow}
                  label="First Name"
                  margin="dense"
                  variant="outlined"
                  value={userDetails ? userDetails.first_name : ''}
                  onChange={(event) => handleFirstNameChange(event.target.value)}

                />



              </div>
            </Grid>
            <Grid container className={classes.row}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '30%' }}>

                <TextField
                  id="last_name"
                  className={classes.textFieldCardRow}
                  label="Last Name"
                  margin="dense"
                  variant="outlined"
                  value={userDetails ? userDetails.last_name : ''}
                  onChange={(event) => handleLastNameChange(event.target.value)}
                />



              </div>
            </Grid>
            <Grid container className={classes.row}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '30%' }}>

                <TextField
                  id="email"
                  className={classes.textFieldCardRow}
                  label="Email"
                  margin="dense"
                  variant="outlined"
                  value={userDetails ? userDetails.email : ''}
                  onChange={(event) => handleEmailChange(event.target.value)}

                />



              </div>
            </Grid>
            <Grid container className={classes.row}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '30%' }}>

                <TextField
                  id="username"
                  className={classes.textFieldCardRow}
                  label="Username"
                  margin="dense"
                  variant="outlined"
                  value={userDetails ? userDetails.username : ''}
                  onChange={(event) => handleUserNameChange(event.target.value)}

                />



              </div>
            </Grid>
            <div>
              <Button size="small" color="primary" variant="outlined">
                Change Password
              </Button>
            </div>

            <div>
              <Button size="small" aria-label="small outlined button group" color="primary" variant="outlined" onClick={handleSaveUserDetails}>
                Save
              </Button>
            </div>
          </div>
        </Card>
      }

    </div>
  )
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveUserLoginData: (p) => { dispatch(saveUserLoginData(p)) },
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);