import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl,  Button } from '@material-ui/core/';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles=makeStyles((theme:Theme)=>
createStyles({
    
})
)

const OrderDeliveryMethod=(props:any)=>{
    console.log("props in checkout",props)
    const classes=useStyles({});
    const [selectedDeliveryMethodType,setSelectedDeliveryMethodType]=useState(null)


   
     
    return (
        <div>
            <Paper style={{marginTop:'20px',display:'flex',flexDirection:'column'}}>
            <FormControl
                  // style={{
                  //   marginRight: 20,
                  // }}
                  required
                  >
                  
                  <InputLabel id="delivery-method-type-select" variant="standard" style={{paddingLeft:'13px'}}>Delivery Method Type</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-delivery-method-type"
                    value={selectedDeliveryMethodType ? selectedDeliveryMethodType: ''}
                    label={"Delivery Type"}
                    inputProps={{ required: true,
                      // readOnly: props.edit_mode
                    }}
                    
                  >
                    {
                      ['Pick Up','Curb'].map((item,index) => (
                        <MenuItem
                          value={item}
                          key={index}
                        >
                          {item}
                        </MenuItem>
                      ))
                    }

                  </Select>
                  {/* <FormHelperText>Required</FormHelperText> */}
                </FormControl>
                <Button size="medium" aria-label="small outlined button group" color="primary" >
                         Payment
                  </Button>

            </Paper>
            </div>
    )

}

export default OrderDeliveryMethod