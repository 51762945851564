import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { uiLoaderReducer } from './reducers/';
import { orderFulfillmentReducers } from './reducers/orderFulfillment/orderFulfillmentReducers';
// import productByCodeReducer from '../modules/products/data/reducers/productByCodeReducer';
// import { productsModuleReducer } from '../modules/products/data/reducers/productReducer';
import { userModuleReducer } from '../modules/users/data/reducers/userModuleReducer';
import { toastReducer } from '../modules/shared/components/Notifier/redux/reducers';

const appReducer = combineReducers({
    toast: toastReducer,
    uiLoader: uiLoaderReducer,
    userData: orderFulfillmentReducers,
    // userModuleData: userModuleReducer
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
    ]
};
const rootReducer = (state: any, action: any) => {
    // if (action.type === 'LOG_OUT') {
    //     state = undefined;
    // }

    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
    let store = createStore<any, any, any, any>(persistedReducer, compose(applyMiddleware(thunk)));
    let persistor = persistStore(store);
    return { store, persistor };
};