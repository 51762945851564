import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography, Icon, Tooltip, Fab } from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Paper } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { PaginatedResponse, Location } from '../../../shared/models';
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
} from '../../../shared/lib/Localization';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// import SwipeableViews from 'react-swipeable-views';
import _uniqBy from 'lodash/uniqBy';
import { defaultGridPaginationQuery } from '../../../shared/lib/TableQueryParams';
import { getProductsByPage } from '../../api/Api'
import CardActions from '@material-ui/core/CardActions';
import _ from 'lodash';
import { addToCart, removeFromCart } from '../../data/actions/cartActions';
import { getProductPriceForPos, roundNumber } from '../../../products/lib/price'
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import BreadCrumbs from '../../layout/components/BreadCrumbs';
import DeleteIcon from '@material-ui/icons/Delete';
import { OrderFulfilllmentPaths } from '../../Routes/Routes'
import { logOutUser } from '../../data/actions/auth'
import cloneDeep from 'lodash/cloneDeep';
import ErrorIcon from '@material-ui/icons/Error'
import { getCommodityGroups } from '../../../pos/Api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    // card: {
    //   padding: theme.spacing(1),
    //   height: 120,
    //   width: 200
    // },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',

    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    paper: {
      height: 100,
      width: 200,
    },

    root: {
      flexGrow: 1,
      //padding:'18px'
      paddingTop: '18px',
      paddingRight: '18px',
      paddingBottom: '18px',

    },
    card: {
      flexGrow: 1,
      paddingBottom: '1px',
      // height: '50%',
      // width: '100%',
      display: 'flex',
      "&:hover": {
        cursor: 'pointer',
      }
    },
    cardGrid: {
      flexGrow: 1,
      margin: '7px',
      padding: '7px',
      height: '300px',
      width: '200px',
      "&:hover": {
        transform: "scale3d(1.05, 1.05, 1)",
        backgroundColor: "#f0f0f1"
      }
    },
    button: {
      position: 'relative',
      bottom: '-41px',
      right: '10px'

    },
    cardAction: {
      display: 'flex',
      // justifyContent:'flex-start',
      // marginTop:'55px',
      // marginLeft : '-5px',
      float: 'right',
      padding: '8px'
    },
    cardContent: {
      // paddingBottom:'2px',
      // flexGrow: 1,
      // height: '15%',
      width: '100%',
      display: 'flex',
      // flexDirection:'row'
    },
    cardContentGrid: {
      flexGrow: 1,
      padding: '18px',
      height: '156px',
      width: '176px',
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    grid: {
      flexGrow: 1,
      padding: '10px',
      // margin:"10px"
    },
    tabImage: {
      height: '150px',
      width: '200px',
      //marginLeft: '10px',
      alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    },
    toggleSwitch: {
      position: 'absolute',
      // top: '63px',
      right: '35px',
      marginBottom: '10px',
      // marginLeft: '10px',
      // alignSelf: 'center',
    },
    statusIcon: {
      fontSize: 32
    },

  }),
);

const initalPageData = {
  count: 0,
  next: null,
  previous: null,
  results: []
} as PaginatedResponse<any>;

const pageSize = 20;

const Home = (props) => {
  const classes = useStyles({});
  const [pageData, setPageData] = useState(initalPageData);
  const [isLoading, setLoading] = useState(false);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [imageData, setImageData] = useState(null)
  const [triggerProducts, setTriggerProducts] = useState(false);
  //const [switchButton, setSwitchButton] = useState(false);
  const [pagination, setPagination] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSize
  });
  const [navPageNumber, setNavPageNumber] = useState(props.history.location.state);
  const [prevPage, setPrevPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [refreshHomeScreen, setRefreshHomeScreen] = useState(false);
  const [filterByCommodityGroup, setFilterByCommodityGroup] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isMobileScreen, setMobileScreen] = useState(false);

  const business_id = props.businessData.id
  // console.log("H")
  console.log("isMobile",props.isMobileScreen);
  useEffect(() => {
    if (props.history.location.state != null) {
      //    console.log("Nav Page Number",props.history.location.state)
      pagination.page = props.history.location.state
      onPageChange
      setNavPageNumber(null)
      props.history.location.state = null
    }
  }, [navPageNumber])


  useEffect(() => {
    return () => {
      if (props.history.action === "POP") {
          props.history.push(OrderFulfilllmentPaths.home, prevPage)
      }
    };
  }, [props.history])

  useEffect(() => {
    if (!initialLoad && props.searchInput.length == 0) {
      // console.log("THIS ONE INPUTLENGTH 0")
      getProductsPaginated(business_id)
    }
    if (props.searchInput.length > 3) {
      // console.log("THIS ONE INPUTLENGTH >3")
      getProductsPaginated(business_id)
    }

  }, [props.searchInput])

  useEffect(() => {
    // console.log("props.searchInput",props.searchInput)
    // console.log("props.searchButtonClicked",props.searchButtonClicked)
    if (props.searchButtonClicked) {
      // console.log("THIS ONE SEARCH BTN CLICKED")
      getProductsPaginated(business_id)

    }
  }, [props.searchButtonClicked])

  useEffect(() => {
    //console.log("inside props.businessData.id")
    if (!props.businessData.id === null) {
      // console.log("BUSSINESS ID NULL")
      getProductsPaginated(business_id)
    }
  }, [props.businessData.id])

  useEffect(() => {
    if (props.businessData.id && filterByCommodityGroup != null) {
      props.setFilterByCommodityGroup(filterByCommodityGroup)
      
    }
  }, [filterByCommodityGroup]) //  if breadcrums is selected then filter by commodity group with new bread crum value

  useEffect(() => {
    if (!initialLoad && props.businessData.id) {
      // console.log("FILTER COMMIDITY")
      getProductsPaginated(business_id)
      setFilterByCommodityGroup(props.filterByCommodityGroup)
    }
  }, [props.filterByCommodityGroup]) // filter by commodity groups from sidedrawer

  useEffect(() => {
    if (props.location.pathname != OrderFulfilllmentPaths.home) {
      props.setData();
    }
    else {
      props.setShowBreadCrumbs(false);
      props.setFilterByCommodityGroup("");
    }
  }, [location.pathname])
  

  useEffect(() => {
    if (refreshHomeScreen === true) {
      // console.log("REFRESH HOME SCRESSN")
      getProductsPaginated(business_id)
    }
  }, [refreshHomeScreen])

  useEffect(() => {
    // console.log("inside useEffect")
    if (triggerProducts == true) {
      renderListView()
    }
    setTriggerProducts(false)

  }, [triggerProducts])

  useEffect(() => {
    window.addEventListener("resize", () => resize());
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []); 

  const onPageChange = (e, pageNumber: number) => {
    //    console.log("onPageChange",props.history.location.search);
    let defaultPage = 1
    setPrevPage(pagination.page)
    //console.log("previous page",pagination.page)
    setPagination({ ...pagination, page: pageNumber });
    if (props.history.location.state === undefined)
      setNavPageNumber(defaultPage)
    else
      setNavPageNumber(props.history.location.state)
    //  console.log("HERE for SECOND ",navPageNumber)

  };

  useEffect(() => {
    if (pagination.page >= 1) {
      // console.log("PAGE >=1")
      getProductsPaginated(business_id)
    }
  }, [pagination])


  const getArticleCodePrice = (data: any) => {
    let price = Number(getProductPriceForPos(data))
    const articleCodes = data.article_codes && cloneDeep(data.article_codes)
    if (articleCodes.length > 0) {
      let containerQty = articleCodes[0].quantity
      return roundNumber((price * containerQty), 2)
    }
    else return price
  }





  const getProductsPaginated = (business_id) => {
    setLoading(true);
    const page = null
    let searchTerm = props.searchInput
    if (!searchTerm || searchTerm.length == 0) {
      searchTerm = null
    }
    getProductsByPage(business_id, pagination.page, pageSize, searchTerm, props.filterByCommodityGroup)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {

        // const original_data={...data}
        // const filtered_products = data.results.filter(product=>product.business_product.prices.length>0)  

        // const data_to_send={...original_data,results:filtered_products}
        //console.log("data", data)
        setPageData({ ...data, results: data.results.filter(p => !p.business_product.deactivated) });
        // setPageData(data)
        setLoading(false);
        if (data.results.length > 0) {
          let products = data.results

          products.map(obj => obj.quantity = 0)
          products.map(obj => obj.quantity_total = 0)
          products.map(obj => obj.price = Number(getArticleCodePrice(obj.business_product)))
          // if(isNaN(parseFloat(getProductPriceForPos(obj.business_product))))
          // {

          // }
          // }
          // console.log("products",products)
          // setAddedQuantityValue(products)
          let img = []
          products.map((p, index) => {
            if (p.master_product) {
              // if(p.image_data && p.image_data.length > 0){  
              let obj = {}
              obj['mp_id'] = p.master_product.id
              obj['image_data'] = p.master_product.image_data
              img.push(obj)
              // console.log("Image-obj",obj)
              // }
            }
          })
          setImageData(img)
          setNoResultsFound(false)
          props.setSearchButtonClicked(false)
          setInitialLoad(false)
        }
        else {
          setNoResultsFound(true)
          setRefreshHomeScreen(true)
          props.setSearchButtonClicked(false)
        }
      })
      .catch((error: any) => {
        //  console.log("inside error", error)
        setLoading(false);
        if (error) {
          if (error.status && error.status === 500) {
            setEmptyDataSourceMessage(ERROR_MSG);
            setErrorStatusVisible(false);
          }
        }

      });
  }

  const navigateToProductDetailScreen = (data: any) => {
    const productDetailUrl = OrderFulfilllmentPaths.productDetail
    data['pageNumber'] = pagination.page
    props.history.push(productDetailUrl, data);
  }

  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      navigateToProductDetailScreen(data)
    }
    return null
  }

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };

  const getAllProductsImagePath = (productData: any) => {
    if (productData) {
      // console.log("productData",productData)
      // console.log("productData.image_data",productData.image_data)

      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getImagePath()
    }
  }

  const handeBackBtn = () => {
    props.history.goBack();
  }



  const handleQuantityIncrement = (p) => {
    props.addToCart(p)
  }


  const handleQuantityDecrement = (p) => {

    props.removeFromCart(p)

  }


  const handleToggleSwitch = () => {
    props.setView(!props.view)
  }

  const resize = () => {
    setMobileScreen(window.innerWidth <= 1050);
  };

  const getQuantityAndButton = (p) => {
    const product = props.userData.cartData.addedItems.find(item => item.uuid === p.uuid)
    const isDisabled = product ? product.business_product.deactivated : p.business_product.deactivated
    // add disabled in ButtonGroup disabled={isDisabled}
    if (product) {
      return (
        <div>
          <ButtonGroup fullWidth={true} disableElevation variant="contained" color="primary">
            {product.quantity > 0 &&
              <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityDecrement(p)}>
                {product.quantity > 1 ? '-' : <DeleteIcon fontSize={"small"} />}

              </Button>
            }
            {product.quantity > 0 &&
              <Button size="small" disabled>
                {product.quantity}
              </Button>
            }
            <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityIncrement(p)}>
              +
            </Button>
          </ButtonGroup>
        </div>
      )
    }
    else {
      return (
        <div>
          <ButtonGroup disableElevation variant="contained" color="primary">

            {
              p.quantity > 0 &&
              <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityDecrement(p)}>
                {p.quantity > 1 ? '-' : <DeleteIcon fontSize={"small"} />}

              </Button>

            }
            {p.quantity > 0 &&
              <Button size="small" disabled>
                {p.quantity}
              </Button>
            }
            <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityIncrement(p)}>
              +
            </Button>
          </ButtonGroup>

        </div>
      )
    }
  }


  const renderListView = () => {
    return pageData.results.map((p, index) => {
      let isSupplierAcc = props.userData.authData && props.userData.authData.isAuthenticated && props.userData.authData.userDetails.is_default_customer_order
      let containerQty = p.business_product.article_codes && p.business_product.article_codes.length > 0 && p.business_product.article_codes[0].quantity
      let isDeactivated = p.business_product.deactivated
      // console.log("p",p)
      return (
        // <div style={{display:'flex',flexDirection:'row', width:'50%', justifyContent:'center',alignContent:'center'}}> 
        <Grid key={index} item xs={12}>
          <Card className={classes.card} key={index}
          >
            <CardContent style={{ paddingBottom: '1px' }} className={classes.cardContent}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div onClick={() => handlePanelProductImageOnClick(p)} style={{ flexDirection: 'column', alignContent: 'center', width: '100%' ,marginRight:10 }}>
                  <div style={{ display: 'flex', textOverflow: "ellipsis", flexDirection: 'row', width: '%' }}>
                    <Typography style={{ fontSize: '0.85rem' }} >
                      {isSupplierAcc ? p.business_product.name + (` ( x ` + (containerQty ? containerQty : '') + ')') : p.business_product.name}
                    </Typography>

                    {/* {
                    isDeactivated &&
                      <Tooltip title="This item cannot be ordered " placement="bottom">
                        <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                      </Tooltip>
                    } */}
                  </div>
                  {!props.businessData.name.includes('Chetak San Francisco') &&
                    p && p.price && !isNaN(p.price) ?
                    <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                      {`$${p.price} `}
                    </Typography>
                    : <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                      {`$0`}
                    </Typography>
                  }
                </div>
                {/* <CardActions > */}
                {getQuantityAndButton(p)}
                {/* </CardActions>  */}
              </div>
            </CardContent>

          </Card>
        </Grid>
        // </div>

        // })

      )
    })
  }


  const renderGridView = () => {
    return pageData.results.map((p, index) => {
      let isSupplierAcc = props.userData.authData && props.userData.authData.isAuthenticated && props.userData.authData.userDetails.is_default_customer_order
      let containerQty = p.business_product.article_codes && p.business_product.article_codes.length > 0 && p.business_product.article_codes[0].quantity
      let isDeactivated = p.business_product.deactivated
      return (
        <div key={index}>
          <Grid key={index} item>
            <Card
              className={classes.cardGrid}
              key={index}
              raised={props.isMobileScreen ? true : false}
            >
              
              <CardMedia
                className={classes.tabImage}
                image={getAllProductsImagePath(p.business_product)}
                onClick={() => handlePanelProductImageOnClick(p)}
                style={{ backgroundSize: 'contain' }}
              />
              {/* <div className={classes.details}> */}
              <CardContent className={classes.cardContentGrid}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
                  <div style={{minHeight : '46px'}}>
                  <Typography style={{ fontSize: '0.79rem' }} variant='h6'>
                    {isSupplierAcc ? p.business_product.name + (` ( x ` + (containerQty ? containerQty : '') + ')') : p.business_product.name}
                  </Typography>
                  </div>
                  {/* {isDeactivated &&
                    <Tooltip title="This item cannot be ordered " placement="bottom">
                      <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                    </Tooltip>
                  } */}
                </div>
                {!props.businessData.name.includes('Chetak San Francisco') &&
                  p && p.price && !isNaN(p.price) ?
                  <Typography style={{ fontSize: '1.2rem' }} variant='h6'>
                    {`$${p.price} `}
                  </Typography>
                  : <Typography style={{ fontSize: '1.2rem' }} variant='h6'>
                    {`$0`}
                  </Typography>
                }
                <CardActions className={classes.cardAction}  >
                {
                  // console.log("total",)

                  }
                  {
                  getQuantityAndButton(p)}
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        </div>
        // })

      )
    })
  }

  return (
    //
    <div className={classes.root}> 
      <div style={{ width: '100%', marginLeft: '1rem' }}>
        {props.showBreadCrumbs &&
          <BreadCrumbs
            breadCrumbs={props.breadCrumbs}
            setFilterByCommodityGroup={setFilterByCommodityGroup}
            handeBackBtn={handeBackBtn}  
        />
        }
      </div>
      {!isMobileScreen ? <IconButton className={classes.toggleSwitch} onClick={() => handleToggleSwitch()}>
        {props.view ? <ListRoundedIcon /> : <GridOnRoundedIcon />}

        
      </IconButton>
        :
      <Fab className={classes.toggleSwitch} onClick={() => handleToggleSwitch()}>
        {props.view ? <ListRoundedIcon /> : <GridOnRoundedIcon />}
      </Fab>}
      {/* <Container fixed> */}
      <div style={{
        // margin: 'auto',
        paddingTop: 16,
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
        // border: `4px solid`
      }}>

        {/* <Paper elevation={3} style={{}}> */}
        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'column' , position:'absolute'}}> */}
        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}> */}

        <Paper elevation={4} style={{ marginTop: '20px' }}>
          {noResultsFound &&
            <div style={{ marginTop: '20px', marginLeft: '37%' }} >
              <Typography style={{ padding: 10 }}>
                No Results found
              </Typography>

            </div>
          }
          {
            isLoading &&
            <div className={classes.rowCenter}>
              <CircularProgress />
            </div>
          }
          <div>
            {props.view == false &&
              //  <Grid className={classes.grid} spacing={2}>
              // <Grid xs={12}>

              <Grid >
                {!isLoading &&
                  renderListView()
                }
              </Grid>

              // </Grid>
              // </Grid>

            }
          </div>
          <div>
            {props.view == true &&

              <Grid container className={classes.grid} spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    {!isLoading &&
                      renderGridView()
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
            <Pagination
              boundaryCount={2}
              // disabled={isPaginationDisabled(isLoading)}
              count={Math.ceil(pageData.count / pageSize)}
              size={props.isMobileScreen ? "small" : "medium"}
              page={pagination.page}
              onChange={onPageChange}
              showFirstButton showLastButton
              style={{ display: 'flex', padding: 0, flexWrap: 'wrap', listStyle: 'none', justifyContent: 'center' }}
            />
          </div>
        </Paper>

        {/* </div> */}
        {/* </Paper> */}
      </div>
      {/* </Container> */}
    </div>
  );

}
const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    businessData: state.userData.businessData
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logOutUser: () => { dispatch(logOutUser()) },
    addToCart: (p) => { dispatch(addToCart(p)) },
    removeFromCart: (p) => { dispatch(removeFromCart(p)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

