import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;
const USER_AUTH_TOKEN='0f3c75af79f3f314bb273005fbe6010495f6cef2'

export const API_URLS={
  productsPaginatedUrl: BASE_URL + 'korona/master/order-fulfillment/linked-mp-list/',
  postOrderOnKorona:BASE_URL+'korona/order-fullfillment/post-customer-order',
  postOrder:BASE_URL+'customer_order/post_order',  
  login:BASE_URL+'core/orderFulfillment/login',
  editProfile:BASE_URL+'core/orderFulfillment-customer/edit_profile/',
  commodityGroupsUrl: BASE_URL + 'korona/commodity-groups/structured_parent_child/',
  addOrUpdateCartURL:BASE_URL+'customer_order/create_or_update_cart',
  getAllSavedListsUrl: BASE_URL + 'customer_order/saved-lists',
  addOrUpdateSavedListItemsUrl: BASE_URL + 'customer_order/add-update/saved-list-items',
  addOrUpdateSavedListUrl : BASE_URL + 'customer_order/add-update/saved-list', 
  viewCustomerOrdersUrl:BASE_URL+'customer_order/view-user-order',
  viewCustomerOrderDetailsUrl:BASE_URL+'customer_order/view-user-order-items',
  matchOrderedItemsUrl : BASE_URL + 'customer_order/match-ordered-products',
  // createStockOrderUrl : BASE_URL + 'customer_order/create-stock-order'
  createStockOrderUrl : BASE_URL + 'korona/stockOrders/'
}


export const getProductsByPage = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  searchTerm?: string | null,
  commodityGroup?:string|null
  // orderBy?: string | number,
  // filters?: any,
  // shouldExport?: boolean
) => {
  //&commodityGroup_id__in=${'76a84d88-1ad2-495c-b56d-4cb8690d68de'}
  let URL=`${API_URLS.productsPaginatedUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  if (searchTerm){
  URL = `${URL}&search=${searchTerm}`
  }
  if (commodityGroup){
    URL=`${URL}&commodityGroup_id__in=${commodityGroup}`
  }
  return composeRequest(URL, 'GET', null,null,USER_AUTH_TOKEN);
};


export const placeOrder=(order_data)=>{
  const URL=API_URLS.postOrder
  return composeRequest(URL,'POST', JSON.stringify(order_data))
}

export const addOrUpdateCart=(order_data)=>{
  const URL=API_URLS.addOrUpdateCartURL
  return composeRequest(URL,'POST', JSON.stringify(order_data))
}

export const UserLogin=(data)=>{
  const URL=API_URLS.login
  return composeRequest(URL,'POST', JSON.stringify(data))

}

export const EditUserProfile=(data)=>{
  const URL=API_URLS.editProfile
  return composeRequest(URL,'POST', JSON.stringify(data))
}


export const getCommodityGroups = (business_id: number | string) => {
  let URL = `${API_URLS.commodityGroupsUrl}?business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
};


export const getSavedListsByCustomer = (customer_id: any) => {
  let URL = `${API_URLS.getAllSavedListsUrl}?korona_customer_id=${customer_id}`;
  return composeRequest(URL, 'GET', null);
};


export const addOrUpdateSavedListItems=(data:any)=>{
  const URL=API_URLS.addOrUpdateSavedListItemsUrl
  return composeRequest(URL,'POST', JSON.stringify(data))
}


export const addOrUpdateSavedList=(data:any)=>{
  const URL=API_URLS.addOrUpdateSavedListUrl
  return composeRequest(URL,'POST', JSON.stringify(data))
}

// export const viewCustomerOrders = (customer_id: number | string) => {
//   let URL = `${API_URLS.viewCustomerOrdersUrl}?customer_id=${customer_id}`;
//   return composeRequest(URL, 'GET', null);
// };

export const viewCustomerOrders = (
  customer_id: number | string,
  page: number | null,
  pageSize: number | null,
) => {
  console.log("========>PAGE,PAGESIZE",page,pageSize)
  let URL=`${API_URLS.viewCustomerOrdersUrl}?customer_id=${customer_id}&page=${page}&page_size=${pageSize}`;
  console.log("========>URL",URL)
  return composeRequest(URL, 'GET', null,null,USER_AUTH_TOKEN);
};



export const matchOrderedProducts=(data:any)=>{
  const URL=API_URLS.matchOrderedItemsUrl
  return composeRequest(URL,'POST', JSON.stringify(data))
}


export const createStockOrder=(data: any)=>{
  const URL=API_URLS.createStockOrderUrl
  return composeRequest(URL,'POST', JSON.stringify(data))
}
