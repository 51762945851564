//import the urls here from router file
import {OrderFulfilllmentPaths} from '../../../Routes/Routes'

export const SideDrawerMenuItems= [
      
      {
        "id":'1',
        "name": "My Profile",
        "url": OrderFulfilllmentPaths.profile
      },
       {
         "id":'2',
         "name": "Wishlist",
         "url": OrderFulfilllmentPaths.wishList
       },
       {
        "id":'4',
        "name": "Orders",
        "url": OrderFulfilllmentPaths.orders
      },
      {
        "id":'3',
        "name": "Logout"
      },
      {
        "id":'5',
        "name": `Log in`,
        "url": OrderFulfilllmentPaths.signIn
      }
    ]

export const handleMenuItemClick=(id)=>{
  switch(id){
    case '1':


      break;
      default:
  }

  
 
      

}