import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
// import NavBar from '../components/NavBar/NavBar';
import { OrderFulfilllmentPaths } from '../../Routes/Routes';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ProductDetail, Home, Cart, Profile, Checkout, OrderDeliveryMethod,
  WishList, VerifyDataScreen, Orders, OrderDetail
} from '../../screens'

import NavBar from '../components/NavBar';
import { getCommodityGroups, viewCustomerOrders } from '../../api/Api';
import Login from '../../screens/Authentication/Login'
import SideDrawer from '../components/SideDrawer/SideDrawer'
import { setBusinessDetails } from '../../data/actions/businessActions';
import { logOutUser } from '../../data/actions/auth'
import { useBeforeunload } from 'react-beforeunload'
const drawerWidth = 200;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    componentroot: {
      display: 'flex',
      // overflow: 'hidden',
    },
    content: {
      height: '90vh',
      // overflow: 'auto',
      flexGrow: 1,
      marginTop: '64px',
      // backgroundColor: theme.palette.background.default,
      // padding: theme.spacing(3),
    },
    root: {
      height: '90vh',
      display: 'flex',
      flexDirection: 'column',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      overflow: 'hidden'

    },
    shrinkDrawerWidth: {
      width: 0
    },
    breadCrumss: {
      color: 'black'
    },
    grandparent: {
      paddingLeft: '0px !important',
      marginLeft: '0px !important',
      left: '-46px !important',

    },
    parent: {
      paddingLeft: '10px !important',
      marginLeft: '10px !important',
      left: '-30px !important',
    },
    child: {
      paddingLeft: '0px !important',
      marginLeft: '0px !important',
      left: '-46px !important',
    }
  }),
);


const OrderFulfillmentLayout = (props: any) => {


  const { id } = useParams<{ id: string }>();
  const classes = useStyles({});
  const history = useHistory();
  const location = useLocation();
  const [tokenVerified, setTokenVerified] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [itemState, setItemState] = useState({});
  const [commodityGroups, setCommodityGroups] = useState([]);
  const [filterByCommodityGroup, setFilterByCommodityGroup] = useState(null);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [showBreadCrumbs, setShowBreadCrumbs] = useState(false)
  const [loginDialogBox, setLoginDialogBox] = useState(false)
  const [isMobileScreen, setMobileScreen] = useState(false);
  const [view, setView] = useState(false);
  const [new_routes, setRoutes] = useState([]);
  // const [businessID,setBusinessID]=useState(null);

  // useEffect(() => {
    // console.log()
    // if(window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload')){
    //   console.log(history.action) 
    // }
    //  window.performance.getEntriesByType('navigation')[0].type.includes('reload')
    //  console.log(window.performance.getEntriesByType('navigation')) 
    //}  
//   }
  // )

  // useBeforeunload(() => {
    //  if(window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload')){
    //    console.log("first====>") 
    //  }
    // if(history.action === "POP")
    // {

    // event.preventDefault()
    //  localStorage.clear()
    // }
    // console.log(window.performance.getEntriesByType("navigation"));
    //  



  // });

  let business_id = props.businessData.id

  const navigate = (path) => { history.push(path); };


  useEffect(() => {
    if (props.businessData.id) {

      getAllCommodityGroups(props.businessData.id)
    }
  }, [props.businessData])

  const findChild = (arr : any, uuid:any) => {
    let child = arr.find((element: any) => {
      if (element.uuid == uuid) {
        structureBreadCrumsData(element)
        return element;
      }
      else if (element.hasChildren) {
        return findChild(element.subItems, uuid);
      }
    })
    return child;
  }

  const setData  = () => {
    if (location.pathname != OrderFulfilllmentPaths.home && location.pathname.split('/')[3] =="categories") { 
      setShowBreadCrumbs(true)
      let uuid = location.pathname.split('/').pop();
      findChild(commodityGroups, uuid)
      setFilterByCommodityGroup(uuid) 
    }
  }

  // useEffect(() => {
  //   if (props.businessData.id) {
  //     getCustomerOrders(props.businessData.id)
  //   }
  // }, [])





  // useEffect(()=>{
  //   if(props && props.userData && props.userData.authData && props.userData.authData.isAuthenticated){
  //     props.logOutUser()
  //   }
  // },[]) to-do change prompt user to login

  // const handleTabClosing = () => {
  //   removeUserDetailsFromLocalStorage()
  // }

  // const alertUser = (event: any) => {
  //   event.preventDefault()
  //   event.returnValue = ''
  // }

  // const removeUserDetailsFromLocalStorage = () => {
  //   alert("closing")
  //   localStorage.clear();
  //   props.logOutUser()
  // }

  const getAllCommodityGroups = (business_id) => {
    // console.log("business_id in commodity groups", business_id)
    getCommodityGroups(business_id)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        const create_child = (child: any, child_index: any, index: any, parent_url: any) => {
          let child_obj = child;
          child_obj['activeRoute'] = child.name;
          if (child.hasChildren) {
            child['subItems'] = child.children.filter((nested_child: any, nested_child_index: any) => {
              return create_child(nested_child, nested_child_index, index, child_obj['route']);
            })
          }
          else {
            child_obj['route'] = "/store/home/categories/" + child.uuid;
            // new_routes.push(child_obj['route'])
            // setRoutes(new_routes);
          }
          return child_obj;
        }
        let data_new = data.filter((item: any, index: any) => {
          if (item.hasChildren) {
            item['subItems'] = item.children.filter((child: any, child_index: any) => {
              return create_child(child, child_index, index, item['route']);
            })
          }
          // else {
          //   new_routes.push(item['route'])
          //   setRoutes(new_routes);
          // }
          return item;
        })
        setCommodityGroups(data_new)
      })
      .catch((error: any) => {
        //  console.log("inside error", error)


      });
  }

  useEffect(() => {
    window.addEventListener("resize", () => resize());
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };

  }, []); //render only once


  const resize = () => {
    // console.log("screenSize", window.innerWidth)
    setMobileScreen(window.innerWidth <= 760);
  };

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  let parent_id = null
  let breadCrumsArray = []

  const getChildData = (child) => {
    // console.log("child", child)
    let flag = 0
    // if (child){
    const parent_found = child.find(element => {
      if (element.uuid === parent_id) {
        flag = 1
        return element
      }
      else if (element.hasChildren) {
        getChildData(element.children)
      }
    }
    )
    // if(flag==1){
    //   console.log("parent found",parent_found)
    //   return parent_found
    // }
    // console.log("parent_found", parent_found)
    return parent_found ? parent_found : null
    // }
    // return
  }



  const structureBreadCrumsData = (selectedOption) => {
    // console.log("selectedOption", selectedOption)
    // const breadCrumsArray=[]
    let obj = null
    // breadCrumsArray.unshift(selectedOption)
    parent_id = selectedOption.parentCommodityGroup_id
    if (parent_id == commodityGroups[0].uuid) {
      breadCrumsArray.push(commodityGroups[0])
      breadCrumsArray.push(selectedOption)

    }
    else {
      breadCrumsArray.push(commodityGroups[0])
      commodityGroups[0].children.forEach(parent => {
        const parent_array = []
        parent_array.push(parent)
        obj = getChildData(parent_array) //first search in outer array 
        if (obj) {
          // console.log("inside if obj", obj)

          if (obj) {
            breadCrumsArray.push(obj)
            breadCrumsArray.push(selectedOption)

          }
        }
        else {
          if (parent.hasChildren) {
            obj = getChildData(parent.children) // if not found check if parent has children and check for parent id in children . ex- grocery - produce - fresh produce (selected option), parent is produce which is inside grocery
            // console.log("inside else obj", obj)
            if (obj) {
              breadCrumsArray.push(parent)
              breadCrumsArray.push(obj)
              breadCrumsArray.push(selectedOption)
            }
          }
          else {
            return
          }
        }
      }
      )
    }
    setBreadCrumbs(breadCrumsArray)
  }


  const getRoute = () => {

    return (
      <div className={classes.componentroot}>

        <NavBar
          history={history}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          handleDrawer={handleDrawer}
          setSearchButtonClicked={setSearchButtonClicked}
          isMobileScreen={isMobileScreen}
          location={location}
          showBreadCrumbs={showBreadCrumbs}
          setFilterByCommodityGroup={setFilterByCommodityGroup}
          setShowBreadCrumbs={setShowBreadCrumbs}
          setBreadCrumbs={setBreadCrumbs}
        />
        <SideDrawer
          commodityGroups={commodityGroups}
          openDrawer={openDrawer}
          handleDrawer={handleDrawer}
          isMobileScreen={isMobileScreen}
          history={history}
          location={location}
          setLoginDialogBox={setLoginDialogBox}
          setShowBreadCrumbs={setShowBreadCrumbs}
          setFilterByCommodityGroup={setFilterByCommodityGroup}
          structureBreadCrumsData={structureBreadCrumsData}

        />
        <main className={classes.content}>
          {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {
            breadCrumbs.map((value)=>{
            return(
            <Button  size="small" className={classes.breadCrumss}  onClick={()=>handleBreadCrumbsButtonClick(value)}>
            {value.name}
            </Button>
            )})
          }
          
        </Breadcrumbs> */}



          <Switch>
            <Route
              exact
              path={OrderFulfilllmentPaths.home}
              render={() => (
                <Home
                  history={history}
                  location={location}
                  searchInput={searchInput}
                  filterByCommodityGroup={""}
                  isMobileScreen={isMobileScreen}
                  searchButtonClicked={searchButtonClicked}
                  setSearchButtonClicked={setSearchButtonClicked}
                  showBreadCrumbs={showBreadCrumbs}
                  breadCrumbs={breadCrumbs}
                  setFilterByCommodityGroup={setFilterByCommodityGroup}
                  view={view}
                  setView={setView}
                  setData={setData}
                  setShowBreadCrumbs={setShowBreadCrumbs}
  
                />
              )}
            />
          <Route
            exact
              path={'/store/home/categories/:id'}
              render={() => (
                <Home
                  history={history}
                  location={location}
                  searchInput={searchInput}
                  filterByCommodityGroup={location.pathname.split('/').pop()}
                  // filterByCommodityGroup={filterByCommodityGroup}
                  isMobileScreen={isMobileScreen}
                  searchButtonClicked={searchButtonClicked}
                  setSearchButtonClicked={setSearchButtonClicked}
                  showBreadCrumbs={showBreadCrumbs}
                  breadCrumbs={breadCrumbs}
                  setFilterByCommodityGroup={setFilterByCommodityGroup}
                  view={view}
                  setView={setView}
                  setData={setData}
                  setShowBreadCrumbs={setShowBreadCrumbs}

                />
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.productDetail}
              render={() => (
                <ProductDetail
                  history={history}
                  isMobileScreen={isMobileScreen}
                  location={location}
                />
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.cart}
              render={() => (
                <Cart
                  isMobileScreen={isMobileScreen}
                  history={history}
                  location={location}
                >
                </Cart>
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.profile}
              render={() => (
                <Profile
                  setLoginDialogBox={setLoginDialogBox}
                >
                </Profile>
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.wishList}
              render={() => (
                <WishList
                  history={history}
                  location={location}
                >
                </WishList>
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.checkout}
              render={() => (
                <Checkout
                  isMobileScreen={isMobileScreen}
                  history={history}
                  location={location}
                >
                </Checkout>
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.orderDeliveryMethod}
              render={() => (
                <OrderDeliveryMethod
                  isMobileScreen={isMobileScreen}
                  history={history}
                  location={location}
                >
                </OrderDeliveryMethod>
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.signIn}
              render={() => (
                <Login
                  isOpen={loginDialogBox}
                  onClose={null}
                  setLoginDialogBox={setLoginDialogBox}
                  history={history}
                  location={location}
                />

              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.verifyStockOrderData}
              render={() => (
                <VerifyDataScreen
                  history={history}
                  location={location}
                />
              )}
            />

            <Route
              exact
              path={OrderFulfilllmentPaths.orders}
              render={() => (
                <Orders
                  history={history}
                  location={location}
                />
              )}
            />
            <Route
              exact
              path={OrderFulfilllmentPaths.orderDetail}
              render={() => (
                <OrderDetail
                  history={history}
                />
              )}
            />

          </Switch>
        </main>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {localStorage.getItem("public_token_verified") &&
        getRoute()}

      <div
        style={{
          width: '100%',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
      </div>

    </div>
  );
};


const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    authData: state.userData.authData,
    businessData: state.userData.businessData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logOutUser: () => { dispatch(logOutUser()) },
    setBusinessDetails: (data) => dispatch(setBusinessDetails(data)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderFulfillmentLayout);