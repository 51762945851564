import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from '@material-ui/core/Button';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { PaginatedResponse, Location } from '../../../shared/models';
import Pagination from '@material-ui/lab/Pagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import _uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
// import { ReferenceCatalogPaths } from '../../Routes/Routes';
import { defaultGridPaginationQuery, initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { getProductsByPage, addOrUpdateSavedListItems } from '../../api/Api'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { addToCart, removeFromCart } from '../../data/actions/cartActions';
//import { addToWishlist } from '../../data/actions/wishListActions';
import { saveUserLoginData } from '../../data/actions/auth';

import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import { getProductPriceForPos } from '../../../products/lib/price'
import cloneDeep from 'lodash/cloneDeep';
import { addNewWishlist } from '../../data/actions/multipleWishListAction';
import { OrderFulfilllmentPaths } from '../../Routes/Routes';
import { productSummaryDataReducer } from '../../../products/data/reducers/productReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //     height: '500%',
    //     width:'100%',
    //     display: 'flex',
    //     margin:'1'
    //   },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(120),
        height: theme.spacing(70)
      },
    },
    productName: {
      position: 'absolute',
      top: '112',
      right: '600',
      marginLeft: '100'
    },
    addButton: {
      position: 'absolute',
      right: '630',
      top: '180',
      marginLeft: '100',

    },
    wishButton: {
      position: 'absolute',
      right: '1630',
      top: '180',
      marginLeft: '100',
    },

    wishDropdown: {
      position: 'absolute',
      left: '1500',
      top: '180',
      marginLeft: '153'
    },
    paper: {
      marginTop: '30px',
      alignItems: 'center',
      padding: '15px'
    },

    tabImage: {
      height: '240px',
      width: '290px',
      marginLeft: '10px',
      alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    }
  })
)

const ProductDetail = (props: any) => {

  const classes = useStyles({});
  const [productData, setProductData] = useState({ ...props.location.state });
  const [newWishlist, setNewWishlist] = useState(null)
  // console.log("props.location.state",props.location.state)
  const [isLoading, setLoading] = useState(false)
  const [addedQuantityValue, setAddedQuantityValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  //  console.log("UsserData=====>", props)


  // useEffect(()=>{
  //   console.log("going back", props)
  // },[props.history.location.pathname])

  useEffect(() => {
    return () => {
      if (props.history.action === "POP") {
        let defaultPage = 1
        let pageNumber = props.location.state.pageNumber === "" ? defaultPage : props.location.state.pageNumber
        //       console.log("page Number", pageNumber)
        props.history.push(OrderFulfilllmentPaths.home, pageNumber)
      }
    };
  }, [props.history])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleQuantityIncrement = (p) => {
    //  console.log("ADD TO CART CALLED")
    props.addToCart(p)
  }

  const handleWishlist = (item: any, defaultList: boolean, list?: any) => {
    let savedList = list ? list : null
    if (props.userData.authData.isAuthenticated && props.userData.multipleWishlistData && props.userData.multipleWishlistData.lists) {
      let savedLists = cloneDeep(props.userData.multipleWishlistData.lists)
      // console.log("Saved-lists", savedLists)

      let itemsExists = savedLists.some(element => element.items && element.items)

      if (defaultList) {
        let newList = cloneDeep(savedLists.find(element => element.is_default))
        //    console.log("Default", defaultList, newList)
        savedList = newList
      }
      if (itemsExists) {
        //    console.log("Inside")
        let exists = savedLists.find(element => element.items && element.items.find(i => i.product.uuid === item.uuid))
        if (!exists) {
          addProductToWishList(item, savedList ? savedList : null)
        }
        else {
          props.showToast({
            message: `Product is already in list ` + exists.name + '!',
            options: ToastError,
          });
        }
      }
      else {
        //    console.log("Came", savedList)
        addProductToWishList(item, savedList ? savedList : null)
      }
    }
    setAnchorEl(null)
  }




  const addProductToWishList = (item: any, list: any) => {
    if (item) {
      let payload = { product: item, saved_list: list }
      //  console.log("Payload", payload)

      addOrUpdateSavedListItems(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: any) => {
          //    console.log("Add SavedListItems", data)
          // setSavedLists(data);
          let index = props.userData.multipleWishlistData.lists.findIndex(i => i.id === list.id)
          let updated = props.userData.multipleWishlistData.lists[index] = cloneDeep(data)
          //console.log("adding to ",props.userData.multipleWishlistData.lists[index])

          props.showToast({
            message: `Product added to list ` + list.name,
            options: ToastSuccess,
          });
          props.addNewWishlist(updated)
        })
        .catch((error: any) => {

        });
    }
  }



  const handleQuantityDecrement = (p) => {

    props.removeFromCart(p)

  }

  useEffect(() => {
    const product = props.userData.cartData.addedItems.find(item => item.uuid === productData.uuid)
    if (!product) {
      //    console.log("inside if")
      getQuantityFromReduxStore(productData)
    }
  }, [handleQuantityDecrement])

  const getQuantityFromReduxStore = (p) => {
    const product = props.userData.cartData.addedItems.find(item => item.uuid === p.uuid)
    if (product) {
      return (
        <div>
          <ButtonGroup disableElevation variant="contained" color="primary">
            {product.quantity > 0 &&
              <Button size="small" variant="contained" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityDecrement(p)}>
                {p.quantity > 1 ? '-' : <DeleteIcon fontSize={"small"} />}
              </Button>
            }
            {product.quantity > 0 &&
              <Button disabled>
                {product.quantity}
              </Button>
            }
            <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityIncrement(p)}>
              +
            </Button>
          </ButtonGroup>

        </div>
      )
    }
    else {

      return (
        <div>
          <ButtonGroup disableElevation variant="contained" color="primary">

            {
              p.quantity > 0 &&
              <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityDecrement(p)}>
                {p.quantity > 1 ? '-' : <DeleteIcon fontSize={"small"} />}
              </Button>
            }
            {p.quantity > 0 &&
              <Button disabled>
                {
                  p.quantity
                }
              </Button>
            }
            <Button size="small" aria-label="small outlined button group" color="primary" onClick={() => handleQuantityIncrement(p)}>
              +
            </Button>
          </ButtonGroup>

        </div>
      )
    }
  }


  const getImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImagePath()
    }
  }

  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };

  return (
    <div>
      {
        isLoading &&
        <div>
          getQuantityFromReduxStore

          <CircularProgress />
        </div>
      }
      <div className={classes.root}>
        {/* <div className={classes.root}> */}

        {/* <Container>
                        <div style={{
                            margin: 'auto',
                            paddingTop: 16,
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center'
                            // border: `4px solid`
                            }}> */}
        <Paper variant="outlined" className={classes.paper} style={{ width: props.isMobileScreen ? "initial" : "100%" }}>
          <img className={classes.tabImage} src={getImagePath(productData.business_product)} style={{ backgroundSize: 'contain' }} />
          <div className={classes.productName}>
            <Typography variant="h5">
              {productData.business_product.name}
            </Typography>
            <Typography >

              {`Commodity Group:${productData.business_product.commodityGroup.name}`}
            </Typography>
            {!props.userData.businessData.name.includes('Chetak San Francisco') &&
              <Typography >

                {`Price:$${productData.price}`}
              </Typography>
            }
            {
              getQuantityFromReduxStore(productData)
            }

            {
              props.userData.authData.isAuthenticated &&
              <>
                <ButtonGroup
                  orientation="vertical"
                  color="primary"
                  variant="contained"
                >
                  <Button
                    className={classes.wishButton}
                    onClick={() => handleWishlist(productData, true)}>
                    <FavoriteBorderIcon />
                  </Button>
                  <Button style={{ marginLeft: 53 }} className={classes.wishDropdown} onClick={handleClick}><ExpandMoreIcon style={{ fontSize: 25 }} /></Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {
                      props.userData.authData && props.userData.authData.isAuthenticated
                      && props.userData.multipleWishlistData && props.userData.multipleWishlistData.lists
                      && sortBy(props.userData.multipleWishlistData.lists, 'is_default').map((l: any, index: any) => {
                        return (
                          <div key={index}>
                            <MenuItem onClick={() => handleWishlist(productData, false, l)} id={index}>{l.name}</MenuItem>
                          </div>
                        )
                      })}
                  </Menu>
                </ButtonGroup>
              </>
            }
          </div>

        </Paper>

      </div>
      {/* </Container> */}
    </div>
  )
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    mulitpleWishlistData: state.userData.mulitpleWishlistData,

  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    addToCart: (p) => { dispatch(addToCart(p)) },
    removeFromCart: (p) => { dispatch(removeFromCart(p)) },
    saveUserLoginData: (p) => { dispatch(saveUserLoginData(p)) },
    //  addNewWishlist: (p) => { dispatch(addNewWishlist(p)) },
    showToast: (toast: Toast) => { dispatch(enqueueSnackbar(toast)) },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)