export const ADD_CART = 'ADD_CART';
export const REMOVE_CART= 'REMOVE_CART';
export const LOG_OUT='LOG_OUT';
export const SAVE_USER_DATA='SAVE_USER_DATA';
export const CHECKOUT_AND_EMPTY_CART='CHECKOUT_AND_EMPTY_CART'
export const SET_BUSINESS_DETAILS='SET_BUSINESS_DETAILS'
export const UPDATE_ORDER_DETAILS='UPDATE_ORDER_DETAILS'
//PAP
export const ADD_WISHLIST='ADD_WISHLIST'
export const REMOVE_WISHLIST='REMOVE_WISHLIST'
export const CLEAR_LIST='CLEAR_LIST'
//DEMO ACTIONS 
export const ADD_NEW_WISHLIST='ADD_NEW_WISHLIST'
export const REMOVE_NEW_WISHLIST='REMOVE_NEW_WISHLIST'
export const TOGGLE_PRODUCT='TOGGLE_PRODUCT'

export const SET_STOCK_ORDER_DATA = 'SET_STOCK_ORDER_DATA';
export const CLEAR_STOCK_ORDER_DATA = 'CLEAR_STOCK_ORDER_DATA';
export const RESET_CART = 'RESET_CART';