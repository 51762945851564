

const initialMultipleWishlistState = {
    lists:[]

};
export default function wishlistDataReducer(state = initialMultipleWishlistState, action: any) {
    switch(action.type){
    case 'ADD_NEW_WISHLIST':
        // let existing_list = state.lists.find(list=>list.name===action.data.name)
        // if(existing_list){
        //      return state;
        // }
        // //if items key does not exists
        // //replace if items present 
        // //
        // else{
            console.log("action.data",action.data)
        //     // let newTotal=state.total+addedItem.price
                    return ({
            ...state,
            lists:action.data,//use concat instead of push when mutating the state
           
         
     }        
  )//}    
     case 'REMOVE_NEW_WISHLIST':
                 //console.log(action.type)listListlistList
        console.log("remove from Wishlist trigged")
        console.log("Action-Data=============>", action.data)   
        console.log("state -Data=============>", state) 
        return ({
            ...state,
            lists:action.data,//use concat instead of push when mutating the state
     }        
  )
    //     const id=action.data.id    
    //     console.log("remove from Wishlist trigged",id)   
    //     let listRemoveExists=state.lists.find(list=>list.id===id)
    //     console.log("=========================>",listRemoveExists) 
    //     if (listRemoveExists){
    //         let newItems=state.lists.filter(list=>list.id!==id)
    //         console.log("TESTING================>",newItems);
    //    return ({
    //             ...state,
    //             lists:newItems,
    //             total_items:state.total_list-1,
    //    })
    //     }

    default: return state     
    }
}